import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  AccordionDetails,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Rating,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoneyDisplay from '../components/MoneyDisplay';
import { styled, useTheme } from '@mui/material/styles';
import '../components/css/Alertpage.css';
import AlertSkeleton from '../components/AlertSkeleton';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ErrorPage from '../components/ErrorPage';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#FFF6E5',
    color: '#474747CC',
    border: '1px solid #dadde9',
  },
}));

const PriceDisplay = ({ label, amount }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
    <Typography sx={{ fontWeight: '500 !important', fontSize: '14px !important', color: "#474747" }} variant="body2">{label}</Typography>
    <MoneyDisplay styles={{ fontWeight: '600 !important', fontSize: '32px !important' }} variant='h5' style={{ fontSize: '32px' }} fontWeight={600} >{amount}</MoneyDisplay>
  </Box>
);
const AlertPage = () => {
  const { product_id } = useParams();
  const navigate = useNavigate();
  const [alertPrice, setAlertPrice] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [showHelperText, setShowHelperText] = useState(false)
  const [message, setMessage] = useState('')
  const [selectedValues, setSelectedValues] = useState({
    'e-commerce platform': null,
    seller: null,
    rating: null,
  });

  const [sellerOptions, setSellerOptions] = useState([]);

  // Handle site change to update the seller options
  const handleSiteChange = (label, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [label.toLowerCase()]: value,
    }));

    if (label === 'E-commerce Platform') {
      if (value) {
        const selectedSiteData = data[0].ecommerce_platforms.find(
          (platform) => platform.site_id === value
        );
        if (selectedSiteData) {
          setSellerOptions(selectedSiteData.sellers);
        } else {
          setSellerOptions([]);
        }
      } else {
        // If no e-commerce platform is selected, show all sellers
        setSellerOptions(data[0].ecommerce_platforms.flatMap(platform => platform.sellers));
      }

      // Reset the seller selection when e-commerce platform changes
      setSelectedValues((prev) => ({
        ...prev,
        seller: '', // Reset seller selection
      }));
    }
  };


  // const initializeSellerOptions = () => {
  //   if (data && data.ecommerce_platforms) {
  //     const allSellers = data.ecommerce_platforms.flatMap((platform) => platform.sellers);
  //     setSellerOptions(allSellers); // Load all sellers after fetch is complete
  //   }
  // };

  const [error, setError] = useState(false)
  console.log("Selected values for Additional value", selectedValues)

  const queryParams = new URLSearchParams(window.location.search);

  const mode = queryParams.get('mode'); // "123"
  const id = queryParams.get('id'); // "alice"
  const theme = useTheme();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };



  const fetchAlertData = async () => {
    setLoading(true); // Ensure loading state is set to true at the start of the fetch
    let response;
    try {
      if (mode == 2) {
        response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/${product_id}?mode=${mode}&id=${id}`);

      } else {
        response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/${product_id}`);
      }

      console.log("url", `${process.env.REACT_APP_EXTERNAL_API}`)
      const result = await response.json();
      console.log("data in set alert page", result)
      if (response.ok) {
        // if (data.length === 0) navigate('/nodata');
        setData(Array.isArray(result) ? result : [result]);

        if (result && result.ecommerce_platforms) {
          const allSellers = result.ecommerce_platforms.flatMap((platform) => platform.sellers);
          setSellerOptions(allSellers); // Load all sellers after fetch is complete
        }
        // console.log("data in alert page",result)
        if (mode == 2) {
          setAlertPrice(result.threshold_price)
          const platform = result.ecommerce_platforms?.[0];
          const alertData = result; // Fetched alert data
          setSelectedValues({
            'e-commerce platform': alertData.site_id,
            rating: parseInt(alertData.ratings),
            seller: alertData.seller_id
          })

          // Compare site_id between alertData and ecommerce_platforms
          const isSiteIdMatchSite = platform?.site_id === alertData.site_id;
          const isSellerIdMatchSeller = platform?.sellers?.[0]?.seller_id === alertData.seller_id;



          console.log("data selected value", selectedValues)
        }

      } else {
        console.log("data not found");
        setError(true)
      }
    } catch (error) {
      console.error('Error fetching alert data:', error);
    } finally {
      setLoading(false); // Ensure loading state is set to false after the fetch
    }
  };

  const handleSetAlert = async () => {

    if (!alertPrice) {
      setShowHelperText(true)
      return
    }
    var values = {
      user_id: 2,
      product_id: product_id,
      threshold_price: alertPrice,
      is_active: true,
      site_id: selectedValues['e-commerce platform'] || data[0]?.ecommerce_platforms[0]?.site_id,
      seller_id: selectedValues.seller || null,
      ratings: selectedValues.rating || null
    };
    console.log("data sent to DB from Alert", values)
    if (mode == '2') {
      values = { ...values, alert_id: id }
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/alerts`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (response.ok) {
        setOpen(true);
        setMessage(data.message);
        setTimeout(() => navigate(`/product-detail/${product_id}?ecommerce=${values.site_id}`), 3000);
      } else {
        setOpen(true);
        setMessage(data.message);
        console.error("Error in response: ", data.message);
      }
    } catch (error) {
      console.error("Error setting alert:", error);
    }
  }

  const handleAlertPriceChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && Number(value >= 0)) {
      setAlertPrice(value);
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAlertData(); // This fetches and sets `data`
      // initializeSellerOptions();
    };
    fetchData();
  }, []);




  return (
    <Box sx={{ margin: '20px' }}>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link color='black' style={{ color: 'black', textDecoration: 'none', fontSize: "small" }} to="/">Home</Link>
        <Link color='black' style={{ color: 'black', textDecoration: 'none', fontSize: "small" }} to="/products">Laptop</Link>
        <Link color='black' style={{ color: 'black', textDecoration: 'none', fontSize: "small" }} to="/electronics">Set Alert</Link>
      </Breadcrumbs>
 
      <Link to="/search" style={{ textDecoration: 'none', marginTop: '10px' }}>
        <Typography fontWeight="bold" color="black" onClick={() => navigate(-1)}>
          &lt; Set Alert
        </Typography>
      </Link>

      {error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ErrorPage />
        </Box>
      ) :
        loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <AlertSkeleton />
          </Box>
        ) : (
          data.map((item, index) => (
            <Box className='Alert-card-body' key={index}>
              <Box className='main-card-body' >
                <Box className='alertpage-image-title'>
                <Box >
                  <Box
                    sx={{
                      minWidth: "200px",
                      minHeight: "140px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: item.product_info.image_url ? `url(${item.product_info.image_url})` : "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: item.product_info.image_url ? "transparent" : "#f0f0f0",
                      color: "#888", // Text color for the fallback text
                      fontSize: "16px",
                      fontWeight: "bold",
                      // border:'2px solid',
                      padding: '5x',
                      borderRadius: '10px'
                    }}
                  >
                    {!item.product_info.image_url && "Product Image"}
                  </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
                    <Tooltip placement="bottom" title={item.product_info.product_name}>
                      <Typography sx={{ fontWeight: '500 !important', fontSize: '24px !important' }} className='product-title' variant="h6" fontWeight='bold'>
                        {`${item.product_info.product_name.substring(0, 70)}...`}
                      </Typography>
                    </Tooltip>
                    <Box mt={1} display="flex" gap={1} sx={{ alignItems: 'center' }}>
                      <Typography sx={{ color: '#2B2B2B', fontWeight: '700 !important', fontSize: '14px !important' }} variant="body1" fontWeight='bold' >
                        {item.product_info.product_rating.toFixed(1)}
                      </Typography>
                      <Rating value={item.rating} size="small" readOnly />
                      <Typography sx={{ color: '#2B2B2B', fontWeight: '500 !important', fontSize: '14px !important' }} variant="body2" fontWeight='bold'>
                        ({item.product_info.total_no_of_rating} ratings)
                      </Typography>
                    </Box>
                    <Box mt={1}>
                      <Typography sx={{ width: "450px", fontWeight: '500 !important', fontSize: '12px !important' }} className='product-description' variant="body2" color="textSecondary">
                        {`${item.product_info.product_description}...`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box margin={2} display="flex" flexDirection={'row'} justifyContent={'space-between'}>
                  <PriceDisplay label="MRP Price" amount={item.product_info.mrp_price} />
                  <PriceDisplay label="Current Price" amount={item.product_info.selling_price} />
                  <PriceDisplay label="Alert Price" amount={alertPrice} />
                </Box>
                <Divider />
                <Box margin="10px 0" mt={2}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography fontWeight={500} sx={{ '&.MuiTypography-root': { fontWeight: '500 !important', }, fontSize: '16px !important' }} mb={1}>Alert Price</Typography>
                    <HtmlTooltip placement="left" title={
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px', padding: '5px', }}>
                        <InfoIcon sx={{ color: '#FFA500', marginTop: '2px' }} fontSize='x-small' />
                        <Typography variant='body2' fontSize={'10px'} fontWeight='500px'>
                          You will be notified when the product's price drops below your set alert price.
                        </Typography>
                      </Box>
                    }>
                      <InfoIcon fontSize='x-small' />
                    </HtmlTooltip>
                  </Box>
                  <TextField
                    fullWidth
                    sx={{
                      "& .MuiFormHelperText-root": {
                        color: showHelperText ? "red" : "black"
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                      '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none !important',
                        margin: 0,
                      },
                    }}
                    helperText={showHelperText ? " Please enter the alert price " : ""}
                    type="number"
                    inputProps={{ min: 0 }}
                    placeholder='Enter Alert Price'
                    variant="outlined"
                    size="small"
                    value={mode == 2 ? Math.floor(alertPrice) : alertPrice}
                    onChange={handleAlertPriceChange}
                    onKeyPress={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Box>
                <Divider />
                <Box margin="10px 0">
                  <Accordion sx={{ boxShadow: 'none', }}>
                    <AccordionSummary sx={{ padding: '0px' }} expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />} aria-controls="panel1-content" id="panel1-header">
                      <Typography color='#000000' fontSize={16} fontWeight={600} variant="h6">Advance Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '8px opx 16px !important' }}
                      sx={{
                        '&.MuiAccordionDetails-root': {
                          padding: '8px 0px 16px !important'
                        }
                      }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                      {['E-commerce Platform', 'Rating', 'Seller'].map((label) => (
  <Box key={label} sx={{ marginBottom: '10px' }}>
    <Typography mb={1} variant="body1" fontWeight="bold">
      {label}
    </Typography>
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      select
      value={
        label === 'E-commerce Platform'
          ? selectedValues['e-commerce platform'] || ''  // Ensure empty value for placeholder
          : label === 'Seller'
          ? selectedValues.seller || ''
          : selectedValues.rating || ''
      }
      onChange={(e) => handleSiteChange(label, e.target.value)}
      SelectProps={{
        displayEmpty: true, // Ensures that empty value displays placeholder
      }}
    >
      {/* Placeholder MenuItem */}
      <MenuItem value="" disabled style={{color:"gray"}}>
        {label === 'E-commerce Platform'
          ? 'Select E-commerce Platform'
          : label === 'Seller'
          ? 'Select Seller Name'
          : 'Select Rating'}
      </MenuItem>

      {/* Dropdown Options */}
      {label === 'E-commerce Platform' &&
        item.ecommerce_platforms.map((platform) => (
          <MenuItem key={`${platform.site_id}-ecommerce`} value={platform.site_id}>
            {platform.site_name}
          </MenuItem>
        ))}
      {label === 'Rating' &&
        [1, 2, 3, 4, 5].map((rating) => (
          <MenuItem key={rating} value={rating}>
            {rating}
          </MenuItem>
        ))}
      {label === 'Seller' &&
        sellerOptions.map((seller) => (
          <MenuItem key={`${seller.seller_id}-seller`} value={seller.seller_id}>
            {seller.seller_name}
          </MenuItem>
        ))}
    </TextField>
  </Box>
))}



                      </Box>


                    </AccordionDetails>
                  </Accordion>
                </Box>
                <FormControlLabel control={<Checkbox />} label="Receive SMS notifications" />
                <Box mt={2} display="flex" gap="15px" justifyContent="flex-end">
                  <Button sx={{ color: '#6C757D', borderRadius: '20px', padding: '5px 30px', fontWeight: '600px', fontSize: '14px', boxShadow: 'none' }} variant="outlined" onClick={() => navigate(-1)} size='small'>Back</Button>
                  <Button
                    disabled={isDisabled}
                    onClick={handleSetAlert}
                    endIcon={<NotificationsActiveOutlinedIcon style={{ fontSize: '16px', fontWeight: '600px' }} />}
                    sx={{
                      borderRadius: '40px',
                      padding: '5px 25px',
                      fontSize: '14px',
                      fontWeight: '600px',
                      boxShadow: 'none'
                    }}
                    variant="contained" color="primary"
                    size='small'
                  >
                    {mode == 2 ? 'Update Alert' : 'Set Alert'}
                  </Button>
                  <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    sx={{
                      '& .MuiSnackbarContent-root': {
                        backgroundColor: '#EFEFEF',
                        boxShadow: 'none'
                      },
                      borderLeft: `15px solid `,
                      borderColor: `${theme.palette.border.primary} !important`,
                      borderRadius: '10px'
                    }}
                    message={
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', }}>
                        <InfoIcon fontSize="small" sx={{ color: '#1DA1F2' }} />
                        <Box sx={{ display: 'flex', marginLeft: '9px', justifyContent: 'flex-start', flexDirection: 'column' }}>
                          <Typography component="span" fontWeight="bold" color='black' sx={{ alignItems: 'center' }}>

                            {mode == 2 ? "Alert update Successfully!" : "Alert Set Successfully!"}
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            sx={{ color: message ? 'red' : 'black' }}
                            component="span"
                          >
                            {message || "You'll be notified when the price drops. Stay tuned for great deals!"}
                          </Typography>

                        </Box>
                      </Box>
                    }
                    action={
                      <Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                          <CloseIcon sx={{ color: 'black' }} fontSize="small" />
                        </IconButton>
                      </Fragment>
                    }
                  />
                </Box>
              </Box>

            </Box>
          ))
        )}
    </Box>
  );
};

export default AlertPage;
