import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RelateProductCard from './RelateProductCard'
import { useParams } from 'react-router-dom'

const RelatedProductList = () => {
  console.log("RelatedProductList rendered");
  const { product_id } = useParams();
  const [relatedData, setRelatedData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRelatedProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://telebisibackend-ajhtafdrhge5bta7.southindia-01.azurewebsites.net/products/${product_id}/similar-products`);
      console.log("url",`https://telebisibackend-ajhtafdrhge5bta7.southindia-01.azurewebsites.net/products/${product_id}/similar-products`)
      
      const data = await response.json();
      if (response.ok) {
        console.log("data in related product fetch", data);
        setRelatedData(Array.isArray(data) ? data : [data]);
        setLoading(false)
      } else {
        console.error("Error fetching related products:", response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRelatedProducts();
  }, [product_id]); // Add dependency to avoid infinite loop

  return (
    <Box mt={5}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ color: 'black' }} variant='h6' fontWeight='bolder'>
          Related products
        </Typography>
        <Button variant='outlined' sx={{ borderRadius: '15px' }}>
          View more
        </Button>
      </Box>
      <Box
        mt={4}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          gap: '20px',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="30vh"
            bgcolor="#f5f5f5"
          >
            <CircularProgress size={60} thickness={5} />
            <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
              Loading, please wait...
            </Typography>
          </Box>
        ) : (
          relatedData.map((product, index) => (
            <Box key={index} sx={{ display: 'inline-block', marginRight: '20px' }}>
              <RelateProductCard productData={product} />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default RelatedProductList;
