import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Alert, Button, IconButton, InputAdornment, InputLabel, Snackbar, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import NearMeIcon from '@mui/icons-material/NearMe';
import '../components/css/ProfileDetail.css'
import { useParams } from 'react-router-dom';
import { object } from 'framer-motion/client';

function ProfileDetailPage({ details }) {
    const [alignment, setAlignment] = useState(details.notification_mode);
    const [address, setAddress] = useState(details.address?.length ? details.address :
        [{ street: '', city: '', state: '', country: '', pincode: '' }]
    )
    const [info, setInfo] = useState([details] || [{}])
    const [isChanged, setIsChanged] = useState(false)
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const theme = useTheme();
    const [error, setError] = useState({
        name: '', lastName: '', gender: '', DOB: '', email: '', phone: '',
        street: '', city: '', state: '', country: '', pincode: ''
    });

    console.log("Details prop:", details);
    // console.log("theme", theme.palette.border.secondary)
    console.log("fetch data come address", address)
    // console.log("fetch data come info",info)

    const validators = {
        name: (value) => /^[a-zA-Z]{0,70}$/.test(value),
        last_name: (value) => /^[a-zA-Z]{0,70}$/.test(value),
        email: (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value),
        gender: (value) => /^[a-zA-Z]*$/.test(value),
        DOB: (value) => /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/.test(value),
        phone: (value) => /^[0-9]{10}$/.test(value),
        street: (value) => /^[a-zA-Z0-9\s]{0,50}$/.test(value),
        city: (value) => /^[a-zA-Z]*$/.test(value),
        state: (value) => /^[a-zA-Z]*$/.test(value),
        country: (value) => /^[a-zA-Z]*$/.test(value),
        pincode: (value) => /^[0-9]{6}$/.test(value),


    }

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== alignment) {
            setAlignment(newAlignment);
            setIsChanged(true);
        }
    };
    const handleAddress = () => {
        setAddress([...address, { street: '', city: '', state: '', country: '', pincode: '' }])
    }

    useEffect(() => {
        setIsChanged(false)
    }, [details])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleRemoveAddress = async (index) => {
        const newaddress = address.filter((_, i) => i !== index)
        setAddress(newaddress)
        setIsChanged(true)
        // setTimeout(() => { window.location.reload(); }, 2000) 
        // await Address_delete()
    }
    const handleAddressChange = (index, field, value) => {
        setIsChanged(true)
        // console.log(`Changing ${field} for info index ${index} to ${value}`);
        const isValid = validators[field](value);
        setError((prv) => ({ ...prv, [field]: isValid ? '' : `${field} is invalid` }))
        const newAddress = [...address];
        newAddress[index] = { ...newAddress[index], [field]: value };

        setAddress(newAddress);
        // console.log("Updated info state:", newAddress);
    }
    const handleInfoChange = (index, field, value) => {
        setIsChanged(true)
        // console.log(`Changing ${field} for info index ${index} to ${value}`);
        const isValid = validators[field](value);
        setError((prv) => ({ ...prv, [field]: isValid ? '' : `${field} is invalid` }))
        const newInfo = [...info];
        newInfo[index] = { ...newInfo[index], [field]: value };
        setInfo(newInfo);
    }

    const handleSave = async () => {
        const isInfoValid = info.every(item => item.name && item.last_name && item.email && item.phone);
        const isAddressValid = address.every(addr => addr.street && addr.city && addr.state && addr.country && addr.pincode);

        if (!isInfoValid || !isAddressValid) {
            setError(prev => ({
                ...prev,
                name: info[0].name ? '' : 'Name is required',
                lastName: info[0].last_name ? '' : 'Last Name is required',
                email: info[0].email ? '' : 'Email is required',
                phone: info[0].phone ? '' : 'Phone is required',
                street: address[0].street ? '' : 'Street is required',
                city: address[0].city ? '' : 'City is required',
                state: address[0].state ? '' : 'State is required',
                country: address[0].country ? '' : 'Country is required',
                pincode: address[0].pincode ? '' : 'Pincode is required',
            }));
            return;
        }

        const addressArray = address.map(addr => ({
            street: addr.street,
            city: addr.city,
            state: addr.state,
            country: addr.country,
            pincode: addr.pincode
        }));

        const updatedData = {
            name: info[0].name,
            last_name: info[0].last_name,
            gender: info[0].gender,
            DOB: info[0].DOB,
            address: addressArray,
            notification_mode: alignment,
            email: info[0].email,
            phone: info[0].phone,
        };

        const profile_update = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update/${id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(updatedData)
                });
                const data = await response.json();
                if (response.ok) {
                    setOpen(true);
                    console.log(data);
                } else {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('Error updating profile:', error);
            }
        };

        await profile_update();
    };


    // console.log("DOB IN PROFILE", formatted_date)
    return (
        <Box sx={{ width: '100%', padding: 0 }}>
            <Box
                mb={2}
                gap={1}
                sx={{
                    display: 'flex',
                    borderLeft: '15px solid ',
                    borderColor: `${theme.palette.border.secondary}  !important`,
                    borderRadius: '12px',
                    bgcolor: '#F4A4031A',
                    alignItems: 'center',
                    padding: '15px',
                }}
            >
                <InfoIcon fontSize="small" sx={{ color: '#F4A403' }} />

                <Box>
                    <Typography
                        component="span"
                        fontWeight="bold"
                        color="#040815"
                        sx={{ alignItems: 'center' }}
                    >
                        Note:
                        <Typography
                            variant="body2"
                            color="#040815"
                            component="span"
                            sx={{ marginLeft: 0.5 }}
                        >
                            Changes to your profile will apply to your account
                        </Typography>
                    </Typography>
                </Box>


            </Box>



            <Box sx={{ padding: '28px', mb: 3, border: '1px solid black', borderRadius: '12px', }}>

                <Typography fontWeight={600} fontSize={20} variant='h6' sx={{ mb: 2 }}>
                    Personal Details
                </Typography>
                {info.map((info, index) => (
                    <Box
                        key={index}
                        component="form"
                        sx={{ '& > :not(style)': 
                            { margin: "5px", }, '& .MuiOutlinedInput-input': 
                            { padding: '6px 10px' }, 
                            // display: 'flex', 
                            display: 'grid', gridTemplateColumns:"1fr 1fr 1fr",
                            flexDirection: 'row',
                             gap: '20px', maxWidth: '100%', 
                             flexWrap: 'wrap' }}
                        noValidate
                        autoComplete="off"
                    >

                        <TextField
                            required
                            className='flex-half'
                            error={!!error.name}
                            helperText={error.name}
                            type="text"
                            id={`name-${index}`} label=" Name" value={info.name} variant="outlined"
                            onChange={(e) => handleInfoChange(index, 'name', e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}

                        />

                        <TextField
                            required
                            className='flex-half'
                            type="text"
                            error={!!error.lastName}
                            helperText={error.lastName}
                            id={`last_name-${index}`} label="Last Name" value={info.last_name} variant="outlined"
                            onChange={(e) => handleInfoChange(index, 'last_name', e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}

                        />

                        <TextField
                            required
                            select
                            className="flex-half"
                            label="Gender"
                            value={info.gender}
                            onChange={(e) => handleInfoChange(index, 'gender', e.target.value)}
                            variant="outlined"
                            onKeyPress={(e) => {
                                if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </TextField>


                        <TextField
                            required
                            className='flex-half'
                            id={`DOB-${index}`} label="Date of Birth"
                            type="date"
                            value={info.DOB ? new Date(info.DOB).toISOString().split('T')[0] : ''}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleInfoChange(index, 'DOB', e.target.value)}
                        />


                        <TextField
                            required
                            className='flex-full'
                            id={`email-${index}`} label="Email" value={info.email}
                            variant="outlined"
                            type='email'
                            helperText={error.email}
                            onChange={(e) => handleInfoChange(index, 'email', e.target.value)}

                        />

                        <TextField
                            required
                            className='flex-full'
                            id={`phone-${index}`} label="Phone" value={info.phone}
                            variant="outlined"
                            // type="tel" 
                            error={!!error.phone}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">+91</InputAdornment>
                                ),
                            }}
                            onKeyPress={(e) => {
                                if (/[^0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            helperText={error.phone}
                            onChange={(e) => handleInfoChange(index, 'phone', e.target.value)}

                        />
                    </Box>))}
            </Box>
            {address.map((addr, index) => {
                console.log("address in map", addr)

                return (
                    <Box key={index} sx={{ padding: '28px', mb: 3, border: '1px solid black', borderRadius: '12px' }}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography fontWeight={600} fontSize={20} variant='h6' sx={{ mb: 2 }}>
                                Address  {index + 1}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {index == 0 && (
                                    <Button disabled={address.length >= 3} onClick={handleAddress} startIcon={<AddIcon fontSize='5px' />} size="small" variant='contained' sx={{ borderRadius: '20px', height: '10%', padding: '15px 10px' }}>
                                        New Address
                                    </Button>
                                )}
                                {index !== 0 && (
                                    <IconButton onClick={() => handleRemoveAddress(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                        <Box
                            component="form"
                            sx={{ '& > :not(style)': { margin: "5px", },
                            '& .MuiOutlinedInput-input':
                             { padding: '6px 10px' }, 
                             display: 'grid', gridTemplateColumns:"1fr 1fr 1fr",flexDirection: 'row', 
                             gap: '20px', maxWidth: '100%',
                              flexWrap: 'wrap' }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                required
                                className='flex-full'
                                id={`street-${index}`}
                                label="Street"
                                error={!!error.street}
                                helperText={error.street}
                                placeholder='Street'
                                value={addr.street || ''}
                                variant="outlined"
                                onChange={(e) => handleAddressChange(index, 'street', e.target.value)}
                                onKeyPress={(e) => {
                                    // Prevent hyphen, numbers, and special characters
                                    if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,  // Forces the label to stay at the top
                                }}
                            />
                            <TextField
                                required
                                type="text"
                                className='flex-half'
                                id={`city-${index}`}
                                label="City"
                                placeholder='City'
                                error={!!error.city}
                                helperText={error.city}
                                value={addr.city || ''}
                                variant="outlined"
                                onChange={(e) => handleAddressChange(index, 'city', e.target.value)}
                                onKeyPress={(e) => {
                                    // Prevent hyphen, numbers, and special characters
                                    if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,  // Forces the label to stay at the top
                                }}
                            />
                            <TextField
                                required
                                className='flex-half'
                                type="text"
                                id={`state-${index}`}
                                label="State"
                                placeholder='State'
                                error={!!error.state}
                                helperText={error.state}
                                value={addr.state || ''}
                                variant="outlined"
                                onChange={(e) => handleAddressChange(index, 'state', e.target.value)}
                                onKeyPress={(e) => {
                                    // Prevent hyphen, numbers, and special characters
                                    if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,  // Forces the label to stay at the top
                                }}
                            />
                            <TextField
                                required
                                type="text"
                                className='flex-half'
                                id={`country-${index}`}
                                label="Country"
                                placeholder='country'
                                error={!!error.country}
                                helperText={error.country}
                                value={addr.country || ''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,  // Forces the label to stay at the top
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => handleAddressChange(index, 'country', e.target.value)}
                            />
                            <TextField
                                required
                                className='flex-half'
                                sx={{
                                    '& input[type=number]': {
                                        MozAppearance: 'textfield',
                                    },
                                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === '-' || /[^0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                type="number"
                                id={`pincode-${index}`}
                                label="Pincode"
                                placeholder='Pincode'
                                error={!!error.pincode}
                                helperText={error.pincode}
                                value={addr.pincode || ''}
                                variant="outlined"
                                onChange={(e) => handleAddressChange(index, 'pincode', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,  // Forces the label to stay at the top
                                }}
                            /><Box
                                className='flex-full address-form-button-body'
                            >
                                <Button className='address-form-button' width={'20%'} variant='contained' sx={{ borderRadius: '20px', padding: '5px 25px' }} startIcon={<NearMeIcon />}>Geo Location</Button>
                            </Box>
                        </Box>
                    </Box>)
            })}

            <Box sx={{ boxShadow: 'none', border: '1px solid black', 
                borderRadius: '12px', margin: '3px',padding:"28px" }}>
                <Typography mb='10px' variant='h6' fontWeight={600} fontSize={20}>
                    Notification Preference
                </Typography>
                <Box mt={2}>
                    <Typography fontWeight={500} 
                    fontSize={14}color='#000000' mb={1}>Select Preference</Typography>
                    <ToggleButtonGroup
                        className='profile-ToggleButtonGroup'
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton
                        
                        className='profile-ToggleButtonGroup-button'
                            sx={{
                                '&.MuiToggleButtonGroup-firstButton':
                                {
                                    borderTopLeftRadius: '4px !important', borderTopRightRadius: '4px !important',
                                    borderBottomLeftRadius: '4px !important', borderBottomRightRadius: '4px !important'
                                },
                                textTransform:'none !important',
                               fontWeight: '500px', fontSize: '14px', borderRadius: '4px'
                            }} value={1}>Mail</ToggleButton>

                        <ToggleButton
                        className='profile-ToggleButtonGroup-button'
                            sx={{
                                '&.MuiToggleButtonGroup-middleButton':
                                {
                                    borderTopLeftRadius: '4px !important', borderTopRightRadius: '4px !important',
                                    borderBottomLeftRadius: '4px !important', borderBottomRightRadius: '4px !important'
                                },
                                textTransform:'none !important',
                               fontWeight: '500px', fontSize: '14px', borderRadius: '8px'
                            }} value={2}>Message</ToggleButton>

                        <ToggleButton
                        className='profile-ToggleButtonGroup-button'
                            sx={{
                                '&.MuiToggleButtonGroup-lastButton':
                                {
                                    borderTopLeftRadius: '4px !important', borderTopRightRadius: '4px !important',
                                    borderBottomLeftRadius: '4px !important', borderBottomRightRadius: '4px !important'
                                },
                                textTransform:'none !important',
                                 fontWeight: '500px', fontSize: '14px', borderRadius: '8px'
                            }} value={3}>Both</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Box mt={3} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={handleSave} disabled={!isChanged || Object.values(error).some(err => err !== '')} mb={2} sx={{ borderRadius: '15px ' }} variant='contained'>
                    Save
                </Button>

                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        your profile updated successfully
                    </Alert>
                </Snackbar>

            </Box>
        </Box>
    )
}

export default ProfileDetailPage