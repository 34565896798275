import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'

const ProductTitleSkeleton = () => {
  return (
    <Box className='product-details-front-page'  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
    {/* Image Skeleton */}
    <Box sx={{ width: '50%' }}>
      <Skeleton variant="rectangular" width="90%" height={280} />
    </Box>
   
    {/* Text and Details Skeleton */}
    <Box sx={{ width: '50%' }}>
      <Box>
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="70%" height={20} />
      </Box>
   
      {/* Ratings and "View More" Skeleton */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '10px 0 10px' }}>
        <Skeleton variant="circular" width={24} height={14} />
        <Skeleton variant="text" width="10%" />
        <Skeleton variant="text" width="15%" />
        <Typography variant="body2" style={{ marginLeft: 'auto' }}>
          <Skeleton variant="text" width={60} />
        </Typography>
      </Box>
   
      {/* Product Description Skeleton */}
      <Skeleton variant="text" width="90%" height={10} />
      <Skeleton variant="text" width="90%" height={10} />
   
      {/* Price and Buttons Skeleton */}
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'space-between' }}>
        <Box>
          <Skeleton variant="text" width="50px" />
          <Skeleton variant="text" width="80px" height={20} />
          <Skeleton variant="text" width="100px" />
        </Box>
        <Box sx={{ display: "flex", gap: "24px", marginTop: '35px' }}>
          <Skeleton variant="rectangular" width={120} height={26} />
          <Skeleton variant="rectangular" width={120} height={26} />
        </Box>
      </Box>
    </Box>
   </Box>     
  )
}

export default ProductTitleSkeleton