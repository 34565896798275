import React from 'react'
import nodata from '../assets/No_data_404.jpg'
import { Box, Typography } from '@mui/material'

const ErrorPage = () => {
  return (
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <img src={nodata} alt='data not found' width={'20%'}  height={'20%'}/>
    <Typography variant='h5' color='black'>
      No data Available
    </Typography>
    <Typography variant='body1' color='textSecondary'>
    Please check back later
    </Typography>
    </Box>
  </Box>   
  )
}

export default ErrorPage