import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';

const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    const browsers = [
        { name: 'Microsoft Edge', regex: /Edg/i },
        { name: 'Google Chrome', regex: /Chrome/i },
        { name: 'Safari', regex: /Safari/i },
        { name: 'Mozilla Firefox', regex: /Firefox/i },
        { name: 'Internet Explorer', regex: /MSIE|Trident/i },
    ];

    for (const browser of browsers) {
        if (browser.regex.test(userAgent)) {
            return browser.name;
        }
    }

    return 'Unknown';
};

const UserDetails = () => {
    const [browser, setBrowser] = useState('');
    const [location, setLocation] = useState(null);
    const [ip, setIp] = useState('');

    useEffect(() => {
        setBrowser(getBrowserName());
        
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            },
            (error) => {
                console.error("Error getting location:", error);
                setLocation("Unable to retrieve location");
            }
        );

        const fetchIp = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIp(response.data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIp();
    }, []);

    return (
        <Box>
            <Typography variant="h6">
                Your browser: {browser}
            </Typography>
            <Typography variant="h6">
                Your location: {location ? `Lat: ${location.latitude || 'Loading..'}, Long: ${location.longitude || 'Loading..'}` : 'Loading...'}
            </Typography>
            <Typography variant="h6">
                USER IP :{ip || 'Loading...'}
            </Typography>
        </Box>
    );
};

export default UserDetails;