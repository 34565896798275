import { Box, Card, ImageList, ImageListItem, } from '@mui/material'
import React, { useState } from 'react'
import './ImageCard.css'
import lapFront from '../assets/Lapfront.jpeg';
import lapClosed from '../assets/Lapclose.jpeg';
import lapRight from '../assets/lapright.jpeg';
import lapLeft from '../assets/Lapleft.jpeg';  
import lapTop from '../assets/LapTop.jpeg';

export const ImageCard = ({ images }) => {
  const [data,setData] = useState(images)
  const [mainimage,setMainimage]=useState(images[0])

  const imagelist =[
    {src:lapFront, alt:'front Image' },
    {src:lapLeft, alt:'left Image' },
    {src:lapTop, alt:'top Image' },
    {src:lapClosed, alt:'closed Image' }
  ];

  return (
      <Box className='image-card-main-body' >   
            <ImageList  className='image-lists'  sx={{width:'20%'}}   cols={1} gap={25}  >
               {data.map((item , index)=>{
                return(
                 <ImageListItem   className='image-lists-items' key={index} onClick={()=>setMainimage(item)}>
                    <img
                    src={item}
                    alt={'product'}
                    className='said-image-list'
                    /> 
                   
                 </ImageListItem>
              )})}
            </ImageList>
            <Box  className='main-image-front-box' sx={{width:'100%'}}>
            <img src={mainimage} className='main-image-front'/>
            </Box>
            </Box> 
  )
}