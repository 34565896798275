import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import s232 from '../assets/s232.jpeg'
import Notificationlist from '../components/NotificationList';
import NoDataPage from '../components/Nodata';

const datas = [
  {
    "product_id": 494352160,
    "user_id": 2,
    "threshold_price": "150000.00",
    "alert_history_id": 1,
    "alert_data": {
      "product_id": "494352160",
      "product_name": "Samsung S24 Ultra 5G 512 GB,  12 GB RAM, Titanium Violet, Mobile Phone(494352160)",
      "product_description": " This Samsung S24 Ultra 5G Mobile Phone image is for illustration purpose only. Actual image may vary.",
      "site_name": "Reliance Digital",
      "seller_id": 9,
      "current_price": "₹139999.00",
      "mrp_price": "₹139999.00",
      "review_count": 0,
      "seller_rating": 0,
      "rating_range": "0",
      "image": s232
    }
  }
];

const NotificationPage = () => {
  const navigate = useNavigate()
  const { user_id } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)


  const FetchNotification = async () => {
    // setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/user/${user_id}`)
      const data = await response.json();
      if (response.ok) {
        console.log("data in fetch", data)
        setData(data)
      } else {
        console.log("Error in fetch")
      }
    } catch (e) {
      console.log("Error" + e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    FetchNotification()
  }, [])

    if(loading) return null

  return (
    <Box margin='0 20px'>
      <Typography
        fontWeight="bold"
        color='black'
        onClick={() => navigate(-1)}
      >  &lt; Notification</Typography>
      <Box>
        {data.length === 0 ? (
          <Box>
            <NoDataPage />
          </Box>
        ) :
          <Notificationlist data={data} />
        }

      </Box>
    </Box>

  )
}

export default NotificationPage
