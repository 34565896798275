import { Box, Button, Card, CardContent, CardMedia, Fab, Rating, Typography } from '@mui/material';
import React, { useState } from 'react';
import CarouselDisplay from './CarouselDisplay';
import RelateProductCard from './RelateProductCard';

export const Allpage = ({ product }) => {


    const groupedProducts = product.reduce((acc, currentProduct) => {
        const category = currentProduct.category;
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(currentProduct);
        return acc;
    }, {});


    return (
        <Box padding={2}>
            {Object.keys(groupedProducts).map((category) => (
                <Box key={category} marginBottom={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                        <Typography variant="h5" gutterBottom>
                            {category}
                        </Typography>
                        <Button size="small" sx={{ borderRadius: "50px", color: "#818181" }}>
                            View All
                        </Button>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: '15px',
                        overflowX: "auto",
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        WebkitOverflowScrolling: 'touch',
                    }}>

                        {groupedProducts[category].map((prod) => (
                            // <Card  key={prod.id}>
                            //     <CardMedia
                            //         component="img"

                            //         sx={{ width: "100%", objectFit: 'contain', padding: '5px' }}
                            //         image={prod.image}
                            //         alt={prod.name}
                            //     />
                            //     <CardContent>
                            //         <Typography variant="h6" gutterBottom>
                            //             {prod.name.slice(0, 30)}
                            //         </Typography>
                            //         <Rating value={prod.rating} readOnly />
                            //         <Typography variant="body2" color="text.secondary">
                            //             ₹{prod.price.toLocaleString()} (best price)
                            //         </Typography>
                            //     </CardContent>
                            // </Card>
                            <Box sx={{ display: 'inline-block', position: 'relative' }}>
                                <RelateProductCard productData={prod} />
                            </Box>
                        ))}

                    </Box>
                </Box>
            ))}

        </Box>
    );
};
