
export const setProductData = (data) => {
  return {
    type: 'SET_PRODUCT_DATA',
    payload: data,
  };
};

export const setAlertMode = (mode, alertData) => ({
  type: 'SET_ALERT_MODE',
  payload: { mode, alertData },
});
