import { Avatar, Badge, Box, Button, Card, CardContent, CardMedia, CircularProgress, IconButton, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import ProfileDetailPage from '../components/ProfileDetailPage';
import AlertDetails from '../components/AlertDetails';
import { useNavigate, useParams } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import '../components/css/Profile.css'




// const profile_data = [
//     {
//         "id": 3,
//         "name": "mathi",
//         "notification_mode": 2,
//         "email": "prathap0955@gmail.com",
//         "phone": "6598324175",
//         "gender": "male",
//         "DOB": "1995-08-25T00:00:00.000Z",
//         "address": {
//             "street": "west street",
//             "citty": "karur",
//             "state": "tamil nadu",
//             "country": "india",
//             "pincode": "632458"
//         },
//         "created_at": "2024-10-29T08:30:13.655Z",
//         "updated_at": "2024-10-29T08:30:13.655Z"
//     }
// ];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const Profile = () => {
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const { id } = useParams();



    const fetch_profile = async () => {
        // setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`)
            const data = await response.json();
            if (response.ok) {
                // setTimeout(()=>{setLoading(false)},3000)    
                setData(Array.isArray(data) ? data : [data]);

            } else {
                console.log("error")
                // setLoading(true)
            }
        } catch (e) {
            console.log("Error", e)
        } finally {
            // setTimeout(()=>{setLoading(false)},3000)
        }

    }
    console.log("data in profile", data)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetch_profile()
    }, []);




    return (
        <Box>
            {loading ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100vh"
                    bgcolor="#f5f5f5"
                >
                    <CircularProgress size={60} thickness={5} />
                    <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
                        Loading, please wait...
                    </Typography>
                </Box>
            ) : (
                data.map((item, index) => (
                    <Box key={index}>
                        <Box className="profile_page_name_web">
                            <Card sx={{ mt: 5, margin: '0 auto', width: '100%', boxShadow: 'none', border: '2px solid black', borderRadius: '12px' }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: ' #1DA1F21A', p: 2 }}>
                                    <Badge
                                        overlap="circular"

                                        badgeContent={<EditIcon
                                            // className='profile_page_avatar_badge_icon'
                                            sx={{
                                                backgroundColor: '#474747',
                                                color: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                border: '1px solid lightgray',
                                                top: '50px',
                                                position: 'relative',
                                                cursor: 'pointer',
                                            }} fontSize='5px' />}
                                    >
                                        <Avatar sx={{ width: 85, height: 85, bgcolor: 'black', color: 'white', position: 'relative', top: '50px' }}>
                                            {item.name.charAt(0)}
                                        </Avatar>
                                    </Badge>
                                </Stack>
                                <CardMedia sx={{ margin: '10px 10px 10px 100px;', padding: '10px' }}>
                                    <Typography
                                        variant='h3' fontWeight={600} fontSize={28} sx={{ mt: 2 }}>
                                        {item.name} {item.last_name}
                                    </Typography>
                                    <Box mt={1} sx={{ display: 'flex', gap: '10px', flexDirection: 'row', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            <MailIcon
                                                sx={{ color: '#474747' }}
                                                fontSize='8px' />

                                            <Typography
                                                fontWeight={500}
                                                fontSize={16}
                                                sx={{ color: '#474747' }}
                                                variant='body2'

                                            >{item.email}</Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            <CallIcon
                                                sx={{
                                                    fontSize:'11px !important',
                                                    backgroundColor: '#474747 !important',
                                                    color: 'white !important',
                                                    borderRadius: '50%',
                                                    padding: '1px',
                                                    // border: '1px solid lightgray',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'lightgray',
                                                    },
                                                }}
                                            />

                                            <Typography fontWeight={500}
                                                fontSize={16} sx={{ color: '#474747' }} variant='body2'>{item.phone}</Typography>
                                        </Box>
                                    </Box>
                                </CardMedia>
                            </Card>
                        </Box>
                        <Box className="profile_page_name_mobile">
                            <Card sx={{ mt: 5, margin: '0 auto', width: '100%', boxShadow: 'none', border: '2px solid black', borderRadius: '12px' }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: ' #1DA1F21A', p: 0 }}>
                                    <Badge
                                        overlap="circular"

                                        badgeContent={<EditIcon
                                            sx={{
                                                backgroundColor: '#474747',
                                                color: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                border: '1px solid lightgray',
                                                top: '25px',
                                                left: '130px',
                                                position: 'relative',
                                                cursor: 'pointer',
                                            }} fontSize='5px' />}
                                    >
                                        <Avatar sx={{ width: 85, height: 85, bgcolor: 'black', color: 'white', position: 'relative', top: '25px', left: '130px', }}>
                                            {item.name.charAt(0)}
                                        </Avatar>
                                    </Badge>
                                </Stack>
                                <CardMedia sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', padding: '10px' }}>
                                    <Typography
                                        fontSize={28}
                                        variant='h3' fontWeight={600} sx={{ mt: 2 }}>
                                        {item.name} {item.last_name}
                                    </Typography>
                                    <Box mt={1} sx={{ display: 'flex', gap: '10px', flexDirection: 'column', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>

                                            <Typography
                                                fontWeight={500}
                                                fontSize={16}
                                                sx={{ color: '#474747' }}
                                                variant='body2'

                                            >{item.email}</Typography>
                                        </Box>
                        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            <Typography fontWeight={500}
                                                fontSize={16} sx={{ color: '#474747' }} variant='body2'>+91 {''}{item.phone}</Typography>
                                        </Box>
                                    </Box>
                                </CardMedia>
                            </Card>
                        </Box>
                        <Box sx={{ margin: '20px auto' }} mt={2}>
                            <Card sx={{ width: '100%', boxShadow: 'none', border: '2px solid black', borderRadius: '12px' }}>
                                <Tabs
                                    sx={{ '&.Mui-selected': { color: 'black !important' }, gap: '15px', margin: '10px' }}
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{ style: { display: 'none' } }}
                                >
                                    <Tab
                                        sx={{
                                            fontWeight: '500px !important',
                                            fontSize: '16px !important',
                                            '&.Mui-selected': { color: '#000000 !important' },
                                            borderRadius: '9px', backgroundColor: value === 0 ? ' #1DA1F21A' : 'transparent',
                                            textTransform: 'none !important'
                                        }} label='Account Settings'
                                        value={0} />

                                    <Tab
                                        sx={{
                                            fontWeight: '500px !important', fontSize: '16px !important',
                                            '&.Mui-selected': { color: '#000000 !important' },
                                            color: 'black',
                                            borderRadius: '9px', backgroundColor: value === 1 ? ' #1DA1F21A' : 'transparent',
                                            textTransform: 'none !important'
                                        }}
                                        label='My Alerts'
                                        value={1} />
                                </Tabs>
                            </Card>
                        </Box>
                        <Box sx={{ margin: '16px 0', padding: '5px', border: '2px solid black', borderRadius: '9px' }}>
                            <Box sx={{ width: '100%' }} mt={2}>
                                <CustomTabPanel value={value} index={0}>
                                    <ProfileDetailPage details={item} />
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1}>
                                    <AlertDetails />
                                </CustomTabPanel>
                            </Box>
                        </Box>
                    </Box>
                ))
            )}
        </Box>
    );
}

export default Profile;
