import { Box, Typography, Rating, Divider, TextField, Accordion, AccordionSummary, AccordionDetails, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

const AlertSkeleton = () => (
  <Box className='Alert-card-body' >
    <Box className='main-card-body' sx={{with:'700px',height:'80%'}}>
    {/* Image and Title Skeleton */}
    <Box   className='alertpage-image-title'>   
      <Box className='alertpage-body-image' >
        <Skeleton variant="rectangular" width={200} height={140} />
      </Box>
      <Box>
        <Typography variant="h5">
          <Skeleton width="60%" />
        </Typography>
        <Box mt={1} display="flex" gap={1} sx={{ alignItems: 'center' }}>
          <Skeleton variant="text" width={30} />
          <Rating value={0} size="small" readOnly />
          <Skeleton variant="text" width={50} />
        </Box>
        <Box mt={1}>
          <Typography variant="body2" color="textSecondary">
            <Skeleton width="80%" />
          </Typography>
        </Box>
      </Box>
    </Box>

    {/* Price Display Skeleton */}
    <Box mt={2} display="flex" justifyContent="space-around">
      <Skeleton variant="text" width={80} />
      <Skeleton variant="text" width={80} />
      <Skeleton variant="text" width={80} />
    </Box>
    <Divider />

    {/* Alert Price Skeleton */}
    <Box margin="10px 0" mt={2}>
      <Box margin={'0 15px'} display={'flex'} justifyContent={'space-between'}>
        <Typography variant='body1'>
          <Skeleton width="40%" />
        </Typography>
        <InfoIcon fontSize='small' />
      </Box>
      <Skeleton variant="rectangular" height={40} />
    </Box>
    <Divider />

    {/* Advanced Settings Skeleton */}
    <Box margin="10px 0">
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <Typography variant="h6">
            <Skeleton width="60%" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {['E-commerce Platform', 'Rating', 'Seller'].map((label, index) => (
              <Box key={index} sx={{ marginBottom: '10px' }}>
                <Typography variant="body1">
                  <Skeleton width="40%" />
                </Typography>
                <Skeleton variant="rectangular" height={40} />
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
    </Box>
  </Box>
);

export default AlertSkeleton;


