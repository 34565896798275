import { Box, Button, Card, LinearProgress, Menu, MenuItem, Rating, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import Fab from '@mui/material/Fab';
import '../components/css/Review.css'


export const Review = () => {

    const[anchorEl, setAnchorEl] = useState(null);
    const open =  Boolean(anchorEl);
    const data = [{
        overallreview: 4.5,
        noofoverallreview: 125,
        overallratings: 4,
        ratingscatagry: {
           "5 Star": 80,   
            "4 Star": 60,
            "3 Star": 40,
            "2 Star": 30,
            "1 Star": 10,
        }
    },
    ]
    const customStyle = {
        height: 5,
        borderRadius: 3,
        backgroundColor: '#f3f3f3',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#FDCC0D',
        },
    };

const handleClick =(event)=>{
    setAnchorEl(event.currentTarget);
}
const handleClose = () => {
    setAnchorEl(null);
  };


    return (
        <Box m={2}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight="bold" variant='h6'>
                    Rating and Reviews
                </Typography>
                <Button 
                size="small" 
                sx={{ width: '120px',borderRadius:"50px",color:"gray" }} 
                variant="outlined" 
                color="primary" 
                onClick={handleClick}
                endIcon={<FilterListIcon />}
                >
                    Filter
                </Button>
                <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{borderRadius:'20px',gap:'20px'}}
                MenuListProps={{
                    'aria-labelledby': 'filter-button',
                  }}
                >
                    <MenuItem onClick={handleClose}>Latest Rating</MenuItem>
                    <MenuItem onClick={handleClose}>Old Rating</MenuItem>
                    <MenuItem onClick={handleClose}>Top Rating</MenuItem>
                    <MenuItem onClick={handleClose}>Worst Rating</MenuItem>
                </Menu>
            </Box>
            {data.map((item) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '20px 0' }} >
                        <Box className='total-review-body' >
                            <Box 
                            sx={{display:'flex',justifyContent:'center'}}
                            >
                            <Typography className='total-reviw-body-rating'   variant='h2' >
                                {item.overallreview}
                            </Typography>
                            </Box>
                            <Typography mb={1}  component='p' variant='body1'>
                                {`of ${item.noofoverallreview} reviews`}
                            </Typography>
                            <Rating size='small' value={item.overallratings} readOnly />
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={'2px'}>
                                {Object.entries(item.ratingscatagry).map(([key, value]) => (
                                    <Box key={key} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                        <Typography className='review-bar-key' fontWeight="bold" fontFamily="inner" color='black' variant='body1'>{key}</Typography>
                                        <Box sx={{ width: '70%' }}>
                                            <LinearProgress sx={customStyle} variant="determinate" value={value} />
                                        </Box>
                                        <Typography className='review-bar-key-value' variant='body2'>{value}</Typography>
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                    </Box>
                )
            })}

        </Box>
    )
}