import { Box, Breadcrumbs, Button, Drawer, IconButton, Link, Typography, useMediaQuery, Skeleton } from "@mui/material";
import FacetList from "../components/FacetList";
import { lazy, Suspense, useEffect, useState } from "react";
import ProductList from "../components/ProductList";
import { useTheme } from "@emotion/react";
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { setProductData } from "../action/Action";
import { useDispatch, useSelector } from "react-redux";
import s232 from '../assets/s232.jpeg'
import { useSearchParams } from "react-router-dom";



const productData = [
    {
        type: "brand",
        "products": [
            {
                "name": "Samsung 10.5 kg, 5 star, Semi-Automatic Top Load Washing Machine (WT10C4260GG/TL, Air Turbo Drying, Light Gray, 2023 Model)",
                "product_description": "    Semi-Automatic top load washing maching: Economical, Low water and energy consumption    Capacity 10.5 kg: Suitable for large families | Water Pressure: (0.05~0.3MPa)(0.5~3.0kg.f/cm²) | Water Consumption : Refer to BEE label    Energy Star rating : 5 Star- Best in class efficiency | Energy Consumption : Refer to BEE label    Manufacturer Warranty:2 years on product, 5 years on motor    Motor: : Higher spin speeds helps in faster wash and drying. With its high speed and efficiency, it's perfect for busy households or those who want to get laundry done quickly and effectively",
                "rating": 3,
                "category": "Mobiles",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 14,
                "brand": "Bosch",
                "site_name": "Amazon IN",
                "image":s232,
                "price":78000
            },
            {
                "name": "Samsung 10.5 kg, 5 star, Semi-Automatic Top Load Washing Machine (WT10C4260GG/TL, Air Turbo Drying, Light Gray, 2023 Model)",
                "product_description": "    Semi-Automatic top load washing maching: Economical, Low water and energy consumption    Capacity 10.5 kg: Suitable for large families | Water Pressure: (0.05~0.3MPa)(0.5~3.0kg.f/cm²) | Water Consumption : Refer to BEE label    Energy Star rating : 5 Star- Best in class efficiency | Energy Consumption : Refer to BEE label    Manufacturer Warranty:2 years on product, 5 years on motor    Motor: : Higher spin speeds helps in faster wash and drying. With its high speed and efficiency, it's perfect for busy households or those who want to get laundry done quickly and effectively",
                "rating": 3,
                "category": "Mobiles",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 14,
                "brand": "Bosch",
                "site_name": "Amazon IN",
                "image":s232,
                "price":78000
            },
            {
                "name": "Samsung 10.5 kg, 5 star, Semi-Automatic Top Load Washing Machine (WT10C4260GG/TL, Air Turbo Drying, Light Gray, 2023 Model)",
                "product_description": "    Semi-Automatic top load washing maching: Economical, Low water and energy consumption    Capacity 10.5 kg: Suitable for large families | Water Pressure: (0.05~0.3MPa)(0.5~3.0kg.f/cm²) | Water Consumption : Refer to BEE label    Energy Star rating : 5 Star- Best in class efficiency | Energy Consumption : Refer to BEE label    Manufacturer Warranty:2 years on product, 5 years on motor    Motor: : Higher spin speeds helps in faster wash and drying. With its high speed and efficiency, it's perfect for busy households or those who want to get laundry done quickly and effectively",
                "rating": 3,
                "category": "Mobiles",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 14,
                "brand": "Bosch",
                "site_name": "Amazon IN",
                "image":s232,
                "price":78000
            },
            {
                "name": "Samsung 10.5 kg, 5 star, Semi-Automatic Top Load Washing Machine (WT10C4260GG/TL, Air Turbo Drying, Light Gray, 2023 Model)",
                "product_description": "    Semi-Automatic top load washing maching: Economical, Low water and energy consumption    Capacity 10.5 kg: Suitable for large families | Water Pressure: (0.05~0.3MPa)(0.5~3.0kg.f/cm²) | Water Consumption : Refer to BEE label    Energy Star rating : 5 Star- Best in class efficiency | Energy Consumption : Refer to BEE label    Manufacturer Warranty:2 years on product, 5 years on motor    Motor: : Higher spin speeds helps in faster wash and drying. With its high speed and efficiency, it's perfect for busy households or those who want to get laundry done quickly and effectively",
                "rating": 3,
                "category": "Mobiles",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 14,
                "brand": "Bosch",
                "site_name": "Amazon IN",
                "image":s232,
                "price":78000
            },
            {
                "name": "Samsung 10.5 kg, 5 star, Semi-Automatic Top Load Washing Machine (WT10C4260GG/TL, Air Turbo Drying, Light Gray, 2023 Model)",
                "product_description": "    Semi-Automatic top load washing maching: Economical, Low water and energy consumption    Capacity 10.5 kg: Suitable for large families | Water Pressure: (0.05~0.3MPa)(0.5~3.0kg.f/cm²) | Water Consumption : Refer to BEE label    Energy Star rating : 5 Star- Best in class efficiency | Energy Consumption : Refer to BEE label    Manufacturer Warranty:2 years on product, 5 years on motor    Motor: : Higher spin speeds helps in faster wash and drying. With its high speed and efficiency, it's perfect for busy households or those who want to get laundry done quickly and effectively",
                "rating": 3,
                "category": "Mobiles",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 14,
                "brand": "Bosch",
                "site_name": "Amazon IN",
                "image":s232,
                "price":78000
            },
            {
                "name": "Boat Energyshroom PB300(Burgundy) + Boat LTG 500 Grey",
                "product_description": "    Product 1: Charges your devices simultaneously with two USB A output ports.    Product 1: Supports 2-way 22.5w (Max) fast charging (Quick Charge 3.0 & Power Delivery charging).    Product 1: The power bank has intelligent input and output controls and LED indicators for a seamless experience.    Product 1: The device offers a world-class built-in 12-layer smart IC protection against short circuits, overcharging, over-discharging, and more!    Product 2: Compatible with Apple iPhone 6/ 6 Plus, 5S/5C/5, iPad Air 1/2, iPad2/3/4, iPad Mini 1/2/3, iPad Retina and iPod Touch    Product 2: Spaceship grade aluminium cable casing ensures long-lasting durability and reliable charging    Product 2: Metal braided heavy-duty cable skin, is stress and stretch resistant, protects against day-to-day wear and tear    Product 2: Provides up to 2.4A data transfer and high-speed charging    ›  See more product details",
                "rating": 4,
                "category": "Mobiles",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 19,
                "brand": "Sony",
                "site_name": "Amazon IN",
                "image":s232,
                "price":65000
            },
            {
                "name": "Samsung 7 Kg Wi-Fi Enabled Inverter Fully-Automatic Front Loading Washing Machine (WW70T502DAX/TL,Inox, AI Control)",
                "product_description": "    Fully-automatic front load washing machine: Best Wash Quality, Energy and Water efficient    Capacity 7 KG: Suitable for families with 3 to 4 members    Product Warranty: 3 years on product, 10 years on motor    1200 rpm: Higher spin speeds helps in faster drying    Number of wash program -22    Also included in the box : 1 machine, 1 outlet pipe, clamps & bolts, installation guide    Hygiene Steam Wash: Give your clothes a really deep and hygienic clean with steam       Inverter Techology: Superior energy efficiency, minimal noise and exceptionally long-lasting performance Diamond Drum: Supremely gentle on your clothes   Show More   ›  See more product details",
                "rating": 4,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 4,
                "brand": "Bosch",
                "site_name": "Amazon IN",
                "image":s232,
                "price":333000
            },
            {
                "name": "Samsung 138 cm (55 inches) 4K Ultra HD Smart QLED TV QA55QN90AAKLXL (Titan Black)",
                "product_description": "    Resolution : 4K Ultra HD (3840 x 2160) | Refresh Rate : 120 Hertz    Connectivity: 4 HDMI ports to connect set top box, Blu-ray speakers or a gaming console | 2 USB ports to connect hard drives or other USB devices | One Connect Box for all your connectivity needs    Smart TV Features : Prime Video, Hotstar, Netflix, Zee5 and many more | PC Mode | Universal Guide | Web Browser | Screen Mirroring    Sound: 60 Watts Output | Powerful Speakers with Dolby Digital Plus    Display: 100% Color Volume | Quantum HDR | Quantum Processor | HLG | Mega Contrast | Supreme UHD Dimming    Warranty Information: 2 year comprehensive warranty on product (1 year standard warranty and 1 years extended warranty provided by the brand from the date of purchase)    ›  See more product details",
                "rating": 3,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 41,
                "brand": "Sony",
                "site_name": "Amazon IN",
                "image":s232,
                "price":50000
            },
            {
                "name": "Samsung 138 cm (55 inches) 4K Ultra HD Smart QLED TV QA55QN90AAKLXL (Titan Black)",
                "product_description": "    Resolution : 4K Ultra HD (3840 x 2160) | Refresh Rate : 120 Hertz    Connectivity: 4 HDMI ports to connect set top box, Blu-ray speakers or a gaming console | 2 USB ports to connect hard drives or other USB devices | One Connect Box for all your connectivity needs    Smart TV Features : Prime Video, Hotstar, Netflix, Zee5 and many more | PC Mode | Universal Guide | Web Browser | Screen Mirroring    Sound: 60 Watts Output | Powerful Speakers with Dolby Digital Plus    Display: 100% Color Volume | Quantum HDR | Quantum Processor | HLG | Mega Contrast | Supreme UHD Dimming    Warranty Information: 2 year comprehensive warranty on product (1 year standard warranty and 1 years extended warranty provided by the brand from the date of purchase)    ›  See more product details",
                "rating": 3,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 41,
                "brand": "Sony",
                "site_name": "Amazon IN",
                "image":s232,
                "price":50000
            },
            {
                "name": "Samsung 138 cm (55 inches) 4K Ultra HD Smart QLED TV QA55QN90AAKLXL (Titan Black)",
                "product_description": "    Resolution : 4K Ultra HD (3840 x 2160) | Refresh Rate : 120 Hertz    Connectivity: 4 HDMI ports to connect set top box, Blu-ray speakers or a gaming console | 2 USB ports to connect hard drives or other USB devices | One Connect Box for all your connectivity needs    Smart TV Features : Prime Video, Hotstar, Netflix, Zee5 and many more | PC Mode | Universal Guide | Web Browser | Screen Mirroring    Sound: 60 Watts Output | Powerful Speakers with Dolby Digital Plus    Display: 100% Color Volume | Quantum HDR | Quantum Processor | HLG | Mega Contrast | Supreme UHD Dimming    Warranty Information: 2 year comprehensive warranty on product (1 year standard warranty and 1 years extended warranty provided by the brand from the date of purchase)    ›  See more product details",
                "rating": 3,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 41,
                "brand": "Sony",
                "site_name": "Amazon IN",
                "image":s232,
                "price":50000
            },
            {
                "name": "Samsung 138 cm (55 inches) 4K Ultra HD Smart QLED TV QA55QN90AAKLXL (Titan Black)",
                "product_description": "    Resolution : 4K Ultra HD (3840 x 2160) | Refresh Rate : 120 Hertz    Connectivity: 4 HDMI ports to connect set top box, Blu-ray speakers or a gaming console | 2 USB ports to connect hard drives or other USB devices | One Connect Box for all your connectivity needs    Smart TV Features : Prime Video, Hotstar, Netflix, Zee5 and many more | PC Mode | Universal Guide | Web Browser | Screen Mirroring    Sound: 60 Watts Output | Powerful Speakers with Dolby Digital Plus    Display: 100% Color Volume | Quantum HDR | Quantum Processor | HLG | Mega Contrast | Supreme UHD Dimming    Warranty Information: 2 year comprehensive warranty on product (1 year standard warranty and 1 years extended warranty provided by the brand from the date of purchase)    ›  See more product details",
                "rating": 3,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 41,
                "brand": "Sony",
                "site_name": "Amazon IN",
                "image":s232,
                "price":50000
            },
            {
                "name": "Samsung 138 cm (55 inches) 4K Ultra HD Smart QLED TV QA55QN90AAKLXL (Titan Black)",
                "product_description": "    Resolution : 4K Ultra HD (3840 x 2160) | Refresh Rate : 120 Hertz    Connectivity: 4 HDMI ports to connect set top box, Blu-ray speakers or a gaming console | 2 USB ports to connect hard drives or other USB devices | One Connect Box for all your connectivity needs    Smart TV Features : Prime Video, Hotstar, Netflix, Zee5 and many more | PC Mode | Universal Guide | Web Browser | Screen Mirroring    Sound: 60 Watts Output | Powerful Speakers with Dolby Digital Plus    Display: 100% Color Volume | Quantum HDR | Quantum Processor | HLG | Mega Contrast | Supreme UHD Dimming    Warranty Information: 2 year comprehensive warranty on product (1 year standard warranty and 1 years extended warranty provided by the brand from the date of purchase)    ›  See more product details",
                "rating": 3,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 41,
                "brand": "Sony",
                "site_name": "Amazon IN",
                "image":s232,
                "price":50000
            },
            {
                "name": "Samsung 138 cm (55 inches) 4K Ultra HD Smart QLED TV QA55QN90AAKLXL (Titan Black)",
                "product_description": "    Resolution : 4K Ultra HD (3840 x 2160) | Refresh Rate : 120 Hertz    Connectivity: 4 HDMI ports to connect set top box, Blu-ray speakers or a gaming console | 2 USB ports to connect hard drives or other USB devices | One Connect Box for all your connectivity needs    Smart TV Features : Prime Video, Hotstar, Netflix, Zee5 and many more | PC Mode | Universal Guide | Web Browser | Screen Mirroring    Sound: 60 Watts Output | Powerful Speakers with Dolby Digital Plus    Display: 100% Color Volume | Quantum HDR | Quantum Processor | HLG | Mega Contrast | Supreme UHD Dimming    Warranty Information: 2 year comprehensive warranty on product (1 year standard warranty and 1 years extended warranty provided by the brand from the date of purchase)    ›  See more product details",
                "rating": 3,
                "category": "Headphone",
                "price": "0.00",
                "country_id": 1,
                "site_id": "1",
                "brand_id": 41,
                "brand": "tata",
                "site_name": "Amazon IN",
                "image":s232,
                "price":50000
            },
        ]
    }
]


function SearchResult() {
    const [isLoading,SetIsLoading] = useState(true)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [product, setProduct] = useState([]);
    const [searchType, setSearchType] = useState('product');
    // const [tempType,setTempType] = useState(urlParamTemp.get("type"));
    const { products , type } = useSelector((state) => state)
    const dispatch = useDispatch();
    const BrandSearch = lazy(()=>import("../components/SearchResultBrand"))
    const SearchList = lazy(()=>import("../components/ProductList"))
    const SearchCategory= lazy(()=>import("../components/SearchResultCategory"))

    const [SearchParams] = useSearchParams()


    const [facets, setFacets] = useState({
        Category: [
            { value: 'electronics', label: 'Electronics', count: 20, selected: false },
        ],
        Brand: [
            { value: 'apple', label: 'Apple', count: 10, selected: false },
            { value: 'samsung', label: 'Samsung', count: 5, selected: false },
        ],
        EcommercePlatform: [
            { value: 'amazon', label: 'Amazon', count: 25, selected: false },
            { value: 'ebay', label: 'eBay', count: 10, selected: false },
        ],
        Rating: [
            { value: '4-5', label: '4 to 5 stars', count: 12, selected: false },
            { value: '3-4', label: '3 to 4 stars', count: 8, selected: false },
        ],
    });

    const handleFacetChange = (facet, value) => {
        setFacets(prevFacets => {
            const updatedFacet = prevFacets[facet].map(option =>
                option.value === value ? { ...option, selected: !option.selected } : option
            );
            const newFacets = { ...prevFacets, [facet]: updatedFacet };

            
            updateQueryParams(newFacets);
            return newFacets;
        });
    };

    const updateQueryParams = (newFacets) => {
        const params = new URLSearchParams(window.location.search);

        // Clear existing facet filters
        params.delete('category');
        params.delete('brand');
        params.delete('platform');

        Object.entries(newFacets).forEach(([facet, options]) => {
            const selectedOptions = options.filter(option => option.selected).map(option => option.label);
            if (selectedOptions.length) {
                params.set(facet, selectedOptions.join(','));
            }
        });

       
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);


    };


    const fetchSearchResult = async (params) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_EXTERNAL_API}/search?keyword=${params}`);
            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }
            const data = await response.json();
            dispatch(setProductData(data)); 
            SetIsLoading(false)
            console.log("Data from DB:", data);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };
    

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    async function fetchFilterResult() {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/filter`);
        const data = await response.json();

        const categoryFacets = data.Category.map(item => ({
            value: item.category_name.toLowerCase().replace(/\s+/g, '-'),
            label: item.category_name,
            count: Math.floor(Math.random() * 100),   
            selected: false
        }));

        const brandFacets = data.Brand.map(item => ({
            value: item.brand_name.toLowerCase().replace(/\s+/g, '-'),
            label: item.brand_name,
            count: Math.floor(Math.random() * 100),
            selected: false
        }));

        const siteFacets = data.Site.map(item => ({
            value: item.site_name.toLowerCase().replace(/\s+/g, '-'),
            label: item.site_name,
            count: Math.floor(Math.random() * 100),  
            selected: false
        }));

        setFacets({
            Category: categoryFacets,
            Brand: brandFacets,
            Site: siteFacets
        });
    }

    



    const params = window.location.href.split("&")[1]
    useEffect(() => {
        fetchSearchResult(SearchParams.get("keyword"))
        // dispatch(setProductData(productData[0]))
    }, [])
    if(isLoading) return null

    return (
        <>
            {isMobile ? <Button endIcon={<FilterAltIcon />} variant="outlined" onClick={() => setDrawerOpen(!drawerOpen)}
                sx={{ marginRight: '0px', borderRadius: '50px', display: "flex", marginLeft: "auto" }}>
                Filter
            </Button> : null}
            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                {isMobile ? <>
                    <Drawer anchor="bottom" PaperProps={{
                        sx: {
                            height: '90%',
                            flexShrink: 0
                        }
                    }} open={drawerOpen} onClose={toggleDrawer}>
                        <Box sx={{ padding: 2 }}>
                            <IconButton onClick={toggleDrawer} sx={{ position: 'absolute', top: 10, right: 10 }}>
                                <CloseIcon />
                            </IconButton>
                            <FacetList facets={facets} onFacetChange={handleFacetChange} />
                        </Box>
                    </Drawer></> : <Box id="searchpage">
                    <FacetList facets={facets} onFacetChange={handleFacetChange} />
                </Box>}
                <Box sx={{ margin: "10px",overflowX:'hidden' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                        >
                           {SearchParams.get("keyword")}
                        </Link>
                    </Breadcrumbs>
                    <Box sx={{ margin: "24px 0px 24px 0px" }}>
                        <Typography variant="p">20 results found</Typography>
                        <Typography variant="h4">{`You Searched "${SearchParams.get("keyword")}"`}</Typography>
                    </Box>
                    <Box sx={{width:'90%'}}>
                        {type === 'product' ? (
                            <Suspense
                            fallback={<div>products are losding</div>}
                            >
                            <SearchList products={products} />
                            </Suspense>
                        ) : type === 'brand' ? (
                            <Suspense
                            fallback={<div>Products Are Loading</div>}
                            >
                            <SearchCategory products={products} />
                            </Suspense>
                        ) : type === 'category' ? (
                            <Suspense
                            fallback={<div>products are loading please wait...</div>}
                            >
                            <BrandSearch products={products} />
                            </Suspense>
                        ):''}

                    </Box>
                </Box>

            </Box>
        </>)
}

export default SearchResult;
