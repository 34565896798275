import { Card, CardContent, CardMedia, Typography, Box, Rating, Button, Link } from '@mui/material';
import './ProductCard.css'
import MoneyDisplay from './MoneyDisplay';
import s232 from '../assets/s232.jpeg'

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';

function ProductCard({ product }) {
    console.log("data in search view",product)
    const navigate = useNavigate()

    function handleCardClick() {
        navigate(`/product-detail/${product.product_id}?ecommerce=${product.site_id}`)
    }
    const handleVisitSite = () => {
        window.open(product.product_url)
    }
    return (
        <Card className="product-view">
            <CardMedia onClick={handleCardClick}
                component="img"
                sx={{ width: "20%", objectFit: 'contain',cursor:'pointer' }}
                image={product.product_image_url}
                alt={product.name}
                className="mobile-view"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, flex: 1 }}>
                <CardContent>
                    <Typography variant="p" component="div" color='#686666'>
                        {product.site_name}
                    </Typography>
                    <Typography variant="h4" component="div" className="product-name" sx={{
                        cursor: 'pointer',
                        color: 'text.primary',
                        '&:hover': {
                            color: 'primary.main', // Change this to your desired color on hover
                        },
                    }} onClick={handleCardClick}>
                        {product.product_name}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '5px', alignItems: "center", margin: "10px" }}>
                        <Typography variant='p'>{product.rating}</Typography>
                        <Rating name="disabled" value={product.ratings} readOnly />
                        <Typography variant='p'>{`(15,404 ratings)`}</Typography>
                        <NavLink className="more-details" to={handleCardClick}>
                            <Typography> More details</Typography>
                        </NavLink>
                    </Box>
                    <Typography variant="p" component="p" className="product-description">
                        {product.product_description}
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'space-between' }}>
                        <Box>
                            <Typography variant='p'>
                                Best Price
                            </Typography>
                            <MoneyDisplay onClick={handleCardClick} variant="h4" sx={{cursor: 'pointer'}}>{product.seller_price}</MoneyDisplay>
                            <Typography variant="p" color='#686666'>
                                Price range starts from{' '}<MoneyDisplay variant="p" color="black" >{product.lowest_price}</MoneyDisplay>{' '}
                                to <MoneyDisplay variant="p" color="black">{parseInt(product.highest_price)}</MoneyDisplay>
                            </Typography>

                        </Box>
                        <Box sx={{ display: "flex", height: "", gap: "24px", marginTop: '35px' }}>
                            <Button onClick={handleVisitSite} variant='outlined' sx={{ borderRadius: "40px" }}>Visit Site</Button>
                            <NavLink to={`/set-alert/${product.product_id}`}>
                            <Button variant='contained' sx={{ borderRadius: "40px" }} endIcon={<NotificationsActiveIcon />}>Set Alert</Button>
                            </NavLink>
                        </Box>

                    </Box>

                </CardContent>
            </Box>
        </Card>
    );
}

export default ProductCard;