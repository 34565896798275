import React from 'react';
import { Typography } from '@mui/material';
import { formatNumberToIndianCurrency } from '../utils/formatnumber';

const MoneyDisplay = ( props ) => {
  const formattedAmount = formatNumberToIndianCurrency(props.children);

  // console.log(formattedAmount)

  return (
    <Typography variant={props.variant} color={props.color} onClick={props.onClick} sx={props.styles?props.styles:null}>
      ₹ {formattedAmount}
    </Typography>
  );
};

export default MoneyDisplay;
