import { Box, Typography, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

function EmptyState() {
    return (
        <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
            <SearchIcon sx={{ fontSize: 80, color: 'grey.500' }} />
            <Typography variant="h5" sx={{ marginTop: 2 }}>
                No Products Found
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, color: 'grey.600' }}>
                Try adjusting your search or filter to find what you're looking for.
            </Typography>
            <Button variant="contained" sx={{ marginTop: 3 }} href="/">
                Go Back Home
            </Button>
        </Box>
    );
}

export default EmptyState;
