import { Box, Button, Card, Divider, Rating, Skeleton, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/material';
// import '../components/css/WebCard.css'
import React, {  useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import MoneyDisplay from './MoneyDisplay';
import Table from '@mui/material/Table';     
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Navigate, useNavigate } from 'react-router-dom';



export const WebCard = ({productId,ecommerce}) => {
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)');
  const [loading,setLoading]=useState(false)
  const [datas,setDatas]=useState([])
const fetchdata = async () =>{
  setLoading(true)
  try{
   const response = await fetch(`${process.env.REACT_APP_EXTERNAL_API}/products/${productId}/compare-seller`)

   const result = await response.json()
   if(response.ok){
    setDatas(Array.isArray? result : [result])
    console.log("data in web card",result)
    setLoading(true)
   }else{
    console.log("error in feych")
   }
  }catch(error){
    console.log("Error",error)
  }finally{
    setLoading(false)
  }
}
 
  const cardsToShow = isExpanded ? datas : (isMobile ? datas.slice(0, 1) : datas.slice(0, 3)) ;
useEffect (()=>{
fetchdata()
},[])

if(loading){
  return(
    <Card variant="outlined" sx={{ maxWidth: 450, borderRadius: '15px' }}>
    <Box sx={{ padding: '3px', background: '#e6f4ff' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Skeleton variant="text" width="40%" height={30} />
        <Box sx={{ display: 'flex' }}>
          <Box m={0}>
            <Skeleton variant="text" width={50} height={24} />
            <Skeleton variant="text" width={60} height={20} />
          </Box>
          <Skeleton variant="circular" width={24} height={24} />
        </Box>
      </Box>
    </Box>
    
    <Divider />

    {/* Skeleton for Best Price and Best Rated Sellers */}
    <Box sx={{ padding: '16px' }}>
      <Table sx={{ borderBottom: 'none' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: '8px', borderRight: '1px solid black' }}>
              <Skeleton variant="text" width="70%" />
            </TableCell>
            <TableCell sx={{ padding: '8px' }}>
              <Skeleton variant="text" width="70%" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: '8px', borderRight: '1px solid black' }}>
              <Box sx={{ margin: '4px 10px' }}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="40%" height={20} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                  <Skeleton variant="text" width="30%" height={30} />
                  <Skeleton variant="rectangular" width={60} height={30} />
                </Box>
              </Box>
            </TableCell>

            <TableCell sx={{ padding: '8px' }}>
              <Box sx={{ margin: '4px 10px' }}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="40%" height={20} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                  <Skeleton variant="text" width="30%" height={30} />
                  <Skeleton variant="rectangular" width={60} height={30} />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  </Card>
  )
}

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "20px" }}
      >

        <Typography
          fontWeight='Bold'
          variant='h5'
        >
          Product Price List
        </Typography>

        {cardsToShow.length>3?<Button
          onClick={() => setIsExpanded(!isExpanded)}
          variant='outlined'
          color='black'
          sx={{ borderRadius: "40px" }}>
          {isExpanded ? 'View Less' : 'View More'}
        </Button>:""}
        {/* <Fab sx={{width:'120px'}} variant="extended"  color="primary" onClick={() => setIsExpanded(!isExpanded)}>
      {isExpanded ? 'View Less' : 'View More'}
      </Fab> */}
      </Box >

      <Box  className='card-body-website'
        sx={{ display: 'grid', overflowX: 'auto', gap: 2, gridAutoRows: 'auto', gridTemplateColumns: isMobile ?'1fr':'repeat(3, 1fr)',}}
      >
        {cardsToShow.map((item,index) => {
          return (
            <Card key={index} variant="outlined" sx={{ maxWidth: 450, borderRadius: '15px' }}>
              <Box sx={{ padding: '3px', background: '#e6f4ff' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography m={2} fontWeight='bold' gutterBottom variant="h6">
                    {item.ecommerceName}
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Box m={0} >
                      <Typography mr={2} fontWeight='Bold' variant='body1' sx={{display:'flex',alignItems:'center'}}>
                        {`${item.ecommerceRating}.0`}
                        <Rating size='small' readOnly value={item.ecommerceRating} />
                      </Typography>
                      <Typography fontSize={'small'} m={0} variant="body2" >
                        {`(${item.total_no_of_rating}) ratings`}
                      </Typography>
                    </Box>
                    <Box>
                      <InfoIcon sx={{ marginRight: '5px' }} fontSize='small' color='disabled' />
                    </Box>
                  </Box>

                </Box>
              </Box>
              <Divider />
                <Table sx={{borderBottom:'none'}}>
                  <TableHead >
                    <TableRow >
                      <TableCell sx={{ padding:'8px', borderRight: '1px solid black' }} align="left">Best Rated Seller</TableCell>
                      <TableCell sx={{padding:'8px'}} align="left">Best Price Seller</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ padding:'4px 2px', borderRight: '1px solid black' }} align="left">
                      <Box sx={{margin:'4px 10px' }}  >
                          <Box sx={{ margin: '0px ' }}>
                              <Box sx={{ margin: '8px 0' }}>
                              <Typography
                                m={0}
                                fontWeight="bold"
                                gutterBottom
                                fontSize='12px'
                                variant="body2"

                              >
                                {`${item.bestRatedSeller.seller_name} `}
                                <Typography
                                  component="span"
                                  variant='body2'
                                  sx={{ color: item.instock > 0 ? "green" : "red" }}
                                >
                                  {`(in Stock)`}
                                </Typography>
                              </Typography>
                              <Box gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ alignItems: 'center' }} variant="body2">
                                  {`${item.bestRatedSeller.seller_ratings ? item.bestRatedSeller.seller_ratings.toFixed(0) : '0' }.0`}
                                </Typography>
                                <Rating value={item.bestRatedSeller.seller_ratings} size='small' readOnly />
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <MoneyDisplay variant="h6">{item.bestRatedSeller.seller_price}</MoneyDisplay>
                              <Button sx={{ background: "#e6f4ff", borderRadius: "18px", color: '#6C757D' }}
                              onClick={()=>window.open(item.bestRatedSeller.url,"_blank", "noopener,noreferrer")}
                              >visit</Button>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{padding:'4px 2px'}} align="left">
                        <Box sx={{margin:'4px 10px' }} >
                          <Box sx={{ margin: '0px' }}>
                            <Box>
                              <Box sx={{ margin: '8px 0' }}>

                                <Typography
                                  m={0}
                                  fontWeight="bold"
                                  gutterBottom
                                  fontSize='12px'
                                  variant="body2"

                                >
                                  {`${item.bestPriceSeller.seller_name} `}
                                  <Typography
                                    component="span"
                                    variant='body2'
                                    sx={{ color: item.instock > 0 ? "green" : "red" }}
                                  >
                                    {`(in Stock)`}
                                  </Typography>
                                </Typography>

                                <Box gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography sx={{ alignItems: 'center' }} variant="body2">
                                  {`${item.bestPriceSeller.seller_ratings ? item.bestPriceSeller.seller_ratings.toFixed(0) : '0'}.0`}
                                  </Typography>
                                  <Rating value={item.bestPriceSeller.seller_ratings} size='small' readOnly />
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <MoneyDisplay variant="h6">{item.bestPriceSeller.seller_price}</MoneyDisplay>
                                <Button onClick={()=>window.open(item.bestPriceSeller.url,"_blank", "noopener,noreferrer")} sx={{ background: "#e6f4ff", borderRadius: "18px", color: '#6C757D' }}>visit</Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>         
            </Card>
          )
        })}
      </Box>
    </Box>
  )
}