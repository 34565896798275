const initialState = {
  type: '',
  products: [],
  mode: 'create',
  alertData: null, // Initialize alertData if needed
};

const searchReducer = (state = initialState, action) => {
  // console.log("data in state",action.payload)
  console.log('Current State:', state);
  console.log('Action Received:', action);
  switch (action.type) {
    case 'SET_PRODUCT_DATA':
      return {
        ...state,
        type: action.payload.type,
        products: action.payload.products,
      };
      case 'SET_ALERT_MODE':
        return {
          ...state,
          mode: action.payload.mode,
          alertData: action.payload.alertData.alert_details,
        };
    default:
      return state;
  }
};

export default searchReducer;
