import { Badge, Box, Card, CardContent, CardMedia, Rating, Typography } from '@mui/material';
import React from 'react';
import MoneyDisplay from './MoneyDisplay';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const RelateProductCard = ({ productData }) => {
  console.log("RelatedProductList rendered");
  console.log('data in related page',productData.product_name)
  // const data = productData.products[0]
  return (
<Box>
    <Card sx={{ boxShadow: 'none', }}>
      <Box  position="relative" display="inline-block" >
        <CardMedia
          sx={{ height:'200px', padding: '15px 0', borderRadius: '15px', border: '2px solid black' }}
        ><img src={productData.product_image} alt={productData.product_name}/>
        </CardMedia>
        <Badge
          color="primary"
          sx={{ position: 'absolute', top: 10, right: 20 ,border:'2px solid black' ,borderRadius:'50px', padding:'3px'}}
        >
          <NotificationsActiveIcon fontSize="small" />
        </Badge>
      </Box>
      <CardContent sx={{ boxShadow: 'none' }}>
        <Box>
          <Typography fontWeight='bold' variant="body1" >
            {productData.product_name}
          </Typography>
          <Box mb={1} display="flex" justifyContent="flex-start" alignItems="center" gap="8px">
            <Typography variant="body1" fontWeight='bold'>
              {`${productData.rating}`}
            </Typography>
            <Rating value={productData.rating} size='small' readOnly />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant='body1' fontWeight="bold">
              <MoneyDisplay  variant="h6" >{productData.best_price}</MoneyDisplay>
            </Typography>
            <Typography variant="caption" sx={{ marginLeft: 1 }}>
              (Best price)
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
    </Box>
  );
};

export default RelateProductCard;
