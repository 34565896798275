import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardMedia, Fab, Grid, Rating, Typography } from '@mui/material';
import productData from '../data/productdata';
import { Allpage } from './Allpage';
import RelateProductCard from './RelateProductCard';

function ProductLandingPage() {

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState(['All'])
    const [selectedCategory, setSelectedCategory] = useState('All')
    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        const fetchProducts = async () => {
            setProducts(productData)
            const uniqueCategories = [
                'All',
                ...new Set(productData.map(product => product.category))
            ]
            setCategories(uniqueCategories)
        }
        fetchProducts();
    }, [])

    const filteredProducts = selectedCategory === 'All'
        ? products
        : products.filter(product => product.category === selectedCategory)

    const handleViewAll = () => {
        setIsExpanded(prev => !prev);
    }
    return (
        <Box padding={2}>
          <Box className="body-for-checkup" sx={{width:'1450px'}} >
            <Box sx={{display:'flex',gap:'15px'}} >
                {categories.map(category => (
                    <Button key={category} size="medium"
                    sx={{borderRadius:'20px' ,}}
                        variant='outlined'
                        backgroundColor={selectedCategory === category ? 'primary' : 'default'}
                        color={selectedCategory === category ? 'primary' : 'default'}
                        onClick={() => setSelectedCategory(category)}
                    >
                        {category}
                    </Button>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', margin: '15px', padding: '5px' }}>
                <Typography key={selectedCategory} variant='h5'>
                    {selectedCategory}
                </Typography>
                {/* {selectedCategory !== 'All' && (
                    <Fab variant='extended' onClick={handleViewAll}>
                        {isExpanded ? 'View less' : 'View All'}
                    </Fab>
                )} */}
            </Box>
            
            {selectedCategory === 'All' ? (
               <Box>   
                <Allpage product={filteredProducts} />
            </Box>
            ) : (
              <Box sx={{padding:'15px'}}>
                <Box 
                sx={{
                  display: 'flex',
                  overflowX: 'auto',
                  gap: '20px',
                  flexWrap: 'nowrap',
                  width: '100%',
                  maxWidth: '100vw',
                  scrollbarWidth: 'none',
                  '&::-webkit-scrollbar': { display: 'none' }, 
                  WebkitOverflowScrolling: 'touch', 
                }}
              >
                {filteredProducts.map((item,index)=>(
                  <Box key={index} sx={{ display: 'inline-block', marginRight: '20px' }}>
                <RelateProductCard productData={item}/>
                </Box>
                ))}
                {/* {filteredProducts.map(product => (
                  <Box key={product.id}  sx={{ flexShrink: 0, width: '250px' }}>
                    <Card sx={{ width: '100%' }}>
                      <CardMedia
                        sx={{ padding: '10px', objectFit: 'cover' }}
                        component="img"
                        height="200"
                        image={product.image}
                        alt={product.name}
                      />
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {product.name.slice(0, 30)}
                        </Typography>
                        <Rating value={product.rating} readOnly />
                        <Typography variant="body2" color="text.secondary">
                          ₹{product.price.toLocaleString()} (best price)
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                ))} */}
              </Box>
              </Box>
            )}

</Box>
        </Box>
    );
}

export default ProductLandingPage