import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Box, Button, Divider, List, ListItemText, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import '../components/css/Specification.css';

const specificationData = [
  {
    "product_detail": "Speed trigger with electronic control for exact pilot drilling. Rotating brush plate for constant power in reverse and forward rotation. Higher dynamic load rated ball bearing for long life. Auxiliary handle with depth stop for better control. Rated power input 600 w. Rated torque 1,8 mmweight without cable: 1. 7 kg. Length 266 mm. Height 180mm. Guarantee 6 month manufacturers guarantee. Disclaimer: product colour may slightly vary due to photographic lighting sources or your monitor settings.",
    "specifications": "Brand: Bosch, Power Source: Corded, Maximum Rotational Speed: 2800 RPM, Voltage: 220 Volts, Amperage: 14 Amps, Maximum Chuck Size: 13 Millimeters, Colour: brown, Included Components: 1 Drill, Special Feature: Reversible, Product Dimensions: 15L x 15W x 15H Centimeters"
  }
];

export const Specification = () => {
  const [isExpanded, setIsExpanded] = useState(false);
    const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { product_id } = useParams();

  const toggleExpand = () => setIsExpanded(!isExpanded);

  
  const FetchData = async () => {
    setLoading(true)
    try {
      const response = await fetch(`https://telebisibackend-ajhtafdrhge5bta7.southindia-01.azurewebsites.net/products/${product_id}/specifications `)

      const data = await response.json()
      if (response.ok) {
        setData(Array.isArray(data) ? data : [data])
        console.log("data from specification", data)
        setLoading(false)
      } else {
        console.error(response.message || "something went wrong")
      }
    } catch (e) {
      console.error("error", e)
    } 

  }

  useEffect(() => {
    FetchData()
  }, [])

  const renderSpecificationSection = (title, details) => (
    <Box mb={2} margin="15px">
    <Typography variant="h6">{title}</Typography>
    <List>
      {Object.entries(details).map(([key, value], index) => (
        <Fragment key={index}>
          <ListItemText>
            <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                className='Specification-details-key'
                variant="body2"
                sx={{
                  minWidth: '250px', 
                  paddingRight: '15px'
                }}
              >
                {key}
              </Typography>
              <Typography
                className='Specification-details-value'
                variant="body2"
              >
                {value}
              </Typography>
            </Box>
          </ListItemText>
          <Divider />
        </Fragment>
      ))}
    </List>
  </Box>
  );

  return (
    <Box mt={8} padding="5px">
      <Typography mb={3} variant="h5" fontWeight="bold">
        Specifications
      </Typography>
      {data.map((item, index) => (
        <Box key={index} sx={{ border: "1px solid black", borderRadius: '15px' }}>
          <Box sx={{ margin: '10px', padding: '15px' }}>
            <Box padding="15px">
              <Typography mb={2} fontWeight="bold" variant="h5">
                Details
              </Typography>
              <Typography variant="body2" color="#474747">
                {item.product_detail}
              </Typography>
            </Box>
            {renderSpecificationSection('Specifications', { 
              'Specifications': item.specifications 
            })}
            {isExpanded && (
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Additional Information: More details can be provided here if available.
                </Typography>
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={toggleExpand}
                sx={{
                  width: '150px',
                  border: '1px solid gray',
                  borderRadius: "18px",
                  color: '#6C757D'
                }}
              >
                {isExpanded ? 'View less' : 'View All'}
              </Button>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
