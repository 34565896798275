import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

function NoDataPage() {
  const handleRetry = () => {
    // Add your retry logic here
    console.log("Retrying...");    
  };

  return (
    <Box
      sx={{
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'text.secondary',
      }}
    >
      <SentimentDissatisfiedIcon sx={{ fontSize: 80, mb: 2, color: 'text.disabled' }} />
      <Typography variant="h5" gutterBottom>
        No Data Available
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Sorry, we couldn't find any data to display.
      </Typography>
    </Box>
  );
}

export default NoDataPage;
