// src/components/Layout.jsx
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Button, Divider, TextField, InputAdornment, IconButton } from '@mui/material';
import LandingPage from './LandingPage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SearchResult from './SearchResultPage';
import ProductCard from '../components/ProductCard';
import { ProductView } from './ProductDetail';
import Alert_page from './SetAlertPage';
import ProfileCard from './Profile';
import UserDetails from '../components/UserDetails';
import TalebisiHeader from '../assets/Talebisi_header.svg'
import TalebisiLogo from '../assets/Talebisi.svg';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NotificationPage from './NotificationPage';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import NoDataPage from '../components/Nodata';
import TriggerBatches from './admin/TriggerBatch';
import SearchIcon from '@mui/icons-material/Search';

const Layout = ({ children }) => {
  const[inputValue,setInputValue]=useState('')
  const navigate = useNavigate()
  const handleStarted = () => {
    navigate('/profile')
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handlechange =( event)=>{
    setInputValue(event.target.value)
    
  }
  const handleSearch = async() => {
    console.log('Search input:', inputValue);
      navigate(inputValue!==''?`/search?keyword=${inputValue}`:"/search")
  }

  return (     
    <div>
      {/* Header */}
      <AppBar position="static" style={{ backgroundColor: 'white',boxShadow:'none',border:'1px solid' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <img src={TalebisiHeader} width="50" onClick={()=>navigate("/")}></img>
          <Box sx={{display:'flex',alignItems:'center',gap:'10px'}} >
          <TextField
            variant="outlined"
            value={inputValue}
            onChange={handlechange}
            placeholder="Search"
            onKeyUp={handleKeyPress}
          // onKeyPress={(e) => {
          //     if (e.key === '-' || /^[0-9]$/.test(e.key) || /[^a-zA-Z\s]/.test(e.key)) {
          //         e.preventDefault();
          //     }
          // }}
            sx={{
              borderRadius: '50px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                height: '40px', // Adjust height as needed
              },
              '& .MuiOutlinedInput-input': {
                padding: '0px 14px', // Adjust padding to fit within the height
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            
            fullWidth
          />
            {/* <Button variant="outlined" component="div" style={{ marginRight: '20px', borderRadius: '50px' }}>
              Sign In
            </Button>
            <Button variant="contained" component="div" style={{ borderRadius: '50px' }}
              onClick={() => navigate("/profile")}>
              Get Started
            </Button> */}
              <NotificationsActiveOutlinedIcon 
              fontSize="small"
              onClick={()=>(navigate('/notification/2'))}
                sx={{
                  backgroundColor: 'white',
                  color: '#474747',
                  borderRadius: '50%',
                  padding: '8px',
                  border: '1px solid lightgray',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }} />
                 <PersonOutlinedIcon fontSize="small"
              onClick={()=>(navigate('/profile/2'))}
                sx={{
                  backgroundColor: 'white',
                  color: '#474747',
                  borderRadius: '50%',
                  padding: '8px',
                  border: '1px solid lightgray',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'lightgray',
                  },
                }} />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Content Area */}
      <Container maxWidth="xl" style={{ paddingTop: '20px', minHeight: '80vh' }}>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/search" element={<SearchResult />}></Route>
          <Route path="/product-detail/:product_id" element={<ProductView />}></Route>
          <Route path="/set-alert/:product_id" element={<Alert_page />}></Route>
          <Route path="/profile/:id" element={<ProfileCard />}></Route>
          <Route path="/user-details" element={<UserDetails />}></Route>
          <Route path="/notification/:user_id" element={<NotificationPage />}></Route>
          <Route path="/nodata" element={<NoDataPage />}></Route>
          <Route path="/admin">
            <Route path="triggerbatch" element={<TriggerBatches/>}></Route>
          </Route>
        </Routes>

      </Container>

      {/* Footer */}

      <Box
        component="footer"
        sx={{
          bgcolor: 'black',
          color: 'white',
          padding: { xs: '10px 0', sm: '20px 0' },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
          sx={{ margin: { xs: '10px 20px', sm: '20px 80px' } }}
        >
          <img src={TalebisiLogo} alt="Talebisi Logo" width="100" />
          <Box display="flex" gap={2}>
            {[
              { Icon: InstagramIcon, link: 'https://instagram.com' },
              { Icon: FacebookIcon, link: 'https://facebook.com' },
              { Icon: LinkedInIcon, link: 'https://linkedin.com' },
              { Icon: XIcon, link: 'https://twitter.com' },
            ].map(({ Icon, link }, index) => (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                style={{ textDecoration: 'none' }}
              >
                <Icon
                  fontSize="small"
                  sx={{
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '50%',
                    padding: '8px',
                    border: '1px solid lightgray',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                />
              </a>
            ))}
          </Box>
        </Box>
        <Divider sx={{ bgcolor: 'white', width: '90%', margin: '0 auto' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            mb: 4,
          }}
        >
          <Typography style={{fontFamily:'Poppins'}} variant="body2">
            © Copyright {new Date().getFullYear()}, All Rights Reserved by ClarityUI
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
