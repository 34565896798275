import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { Box, Stack, TextField, MenuItem, Typography, Button, useTheme, ListItemIcon, Menu, CircularProgress, Alert, Snackbar, } from '@mui/material';
import { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import '../components/css/alert_details.css'
import MoneyDisplay from './MoneyDisplay';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationItem from '@mui/material/PaginationItem';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ErrorPage from './ErrorPage';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlertMode } from '../action/Action';



export default function AlertDetails() {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false);
  const [message, setMessage] = useState('')
  const [snackMode,setSnackMode] = useState('success')
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme()
  const dispatch = useDispatch();
  useEffect(() => {
    handleFetch()
  }, []);
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleFetch = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/user/${id}`);
      const result = await response.json();
      if (response.ok) {
        setLoading(true) 
        const sortedAlerts = result.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        setValues(sortedAlerts)
        console.log("data in profile alert details", result)
      } else if (response.status === 404) {
        setIsNotFound(true);
      } else {
        console.log("error")
      }

    } catch (e) {
      console.error("Fetch error:", e);
    } finally {
      setLoading(false)
    }
  }

  const handleIconClick = (event, productId) => {
    setAnchorEl({ target: event.currentTarget, productId });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (item) => {
    dispatch(setAlertMode('edit', item));
     navigate(`/set-alert/${item.productDetails.product_info.product_id}?mode=2&id=${item.alert_id}`)
    // console.log('Edit:', item);
    handleClose();
  };
  const toggleAlert = async (item) => {
    console.log("items",item)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/toggle/${item.alert_id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_active: !item.is_active, 
          user_id: id
        }),
      });
   const data = response.json();
      if (response.ok) {
        
        setValues(prevAlerts =>
          prevAlerts.map(alert =>
            alert.alert_id === item.alert_id
              ? { ...alert, is_active: !alert.is_active }
              : alert
          )
        );
        
        setMessage("Alert Status Changed Successfully")
        setOpen(true)
      } else if(response.status==403){
        setMessage("Active Alert Limit Reached, Please deactive any active alerts")
        setSnackMode("error")
        setOpen(true)
      }
    } catch (error) {
      console.error("Error updating alert status", error);
    }
  };
  
  const handleDelete = async (item) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/${item}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.text();
      if (response.ok) {
        setMessage(data)
        console.log('Resource deleted:', data);
        await handleFetch()
      } else {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Failed to delete resource:', error);
    }
    setOpen(true);
    console.log('Delete:', item);
    handleClose();
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const BackButton = () => (
    <Typography component="span" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
      <NavigateBeforeIcon /> Back
    </Typography>
  );
  const NextButton = () => (
    <Typography component="span" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
      Next <NavigateNextIcon />
    </Typography>
  );
  if (isNotFound) {
    return <ErrorPage />;
  }

  const formatted_date = (inputDate) => {
    if (!inputDate) return "N/A";
    const date = new Date(inputDate).toISOString().split('T')[0];
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    //  console.log("formatted date in profile", inputDate, date, formattedDate)
    return formattedDate
  }
  return (
    loading ? (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="68vh"
        bgcolor="#f5f5f5"
      >
        <CircularProgress size={60} thickness={5} />
        <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
          Loading, please wait...
        </Typography>
      </Box>
    ) : (
      <Box sx={{ width: '100%', overflow: 'hidden', borderRadius: 2, }}>
        <Typography variant="h6" fontWeight={600} fontSize={20} color='black' gutterBottom sx={{ marginBottom: 1 }}>
          My Alerts
        </Typography>
        <Box
          mb={2}
          gap={1}
          sx={{
            display: 'flex',
            borderLeft: '15px solid #F4A403',
            borderColor: `${theme.palette.border.secondary}  !important;`,
            borderRadius: '11px',
            bgcolor: '#F4A4031A',
            alignItems: 'center',
            padding: '15px',
          }}
        >
          <InfoIcon fontSize="small" sx={{ color: '#F4A403' }} />

          <Typography
            variant='body1'
            fontWeight="bold"
            sx={{ alignItems: 'center' }}
          >
            Note:
            <Typography
              variant="body2"
              color="textSecondary"
              component="span"
              sx={{ marginLeft: 0.5 }}
            >
              You can set up to 3 alerts at a time, and each alert will last for 4 weeks.
            </Typography>
          </Typography>
        </Box>

        <TableContainer component={Paper} sx={{ width: '99%', marginBottom: 2, border: '1px solid', borderRadius: '15px', boxShadow: 'none', }}>
          <Box sx={{ minHeight: '400px', borderbottom: '2px solid' }}>
            <Table className='table-body' sx={{ borderBottom: '1px solid', }} aria-label="alert details table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#E5EEFA' }}>
                  <TableCell align="center"
                    sx={{lineHeight:'18px !important', fontWeight: '500px !important',fontSize:'14px !important' }}>Product Details</TableCell>

                  <TableCell
                    align="center"
                    sx={{ fontWeight: 'bold', display: { xs: 'none', sm: 'table-cell' },lineHeight:'18px !important', fontWeight: '500px !important',fontSize:'14px !important' }}
                  >
                    Alert
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ fontWeight: 'bold', display: { xs: 'none', sm: 'table-cell' },lineHeight:'18px !important', fontWeight: '500px !important',fontSize:'14px !important' }}
                  >
                    Starting Price
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ fontWeight: 'bold', display: { xs: 'none', sm: 'table-cell' },lineHeight:'18px !important', fontWeight: '500px !important',fontSize:'14px !important' }}
                  >
                    Current Price
                  </TableCell>

                  <TableCell align="center" sx={{lineHeight:'18px !important', fontWeight: '500px !important',fontSize:'14px !important' }}>Alert Price</TableCell>

                  <TableCell align="center" sx={{ lineHeight:'18px !important', fontWeight: '500px !important',fontSize:'14px !important' }}>Alert Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ margin: '10px' }}>
                {values.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  // console.log("r")
                  <TableRow

                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f5f5f5', width: '10%' } }}
                  >
                    <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold', padding: '16px 2px', marginLeft: '10px' }}>
                      <img src={row.productDetails.product_info.image_url} alt={row.productDetails.product_info.image_url} className='list-image' />
                      <Typography  variant='body1' fontWeight={500} fontSize={14}
                     className='product-title-profile'> {row.productDetails.product_info.product_name.slice(0, 40)}...</Typography>
                    </TableCell>

                    <TableCell
                    fontWeight={400} fontSize={12}
                      align="center"
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    >
                      {formatted_date(row.created_at)}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    ><MoneyDisplay   fontWeight={400} fontSize={12} variant='body1'>{row.productDetails.product_info.mrp_price}</MoneyDisplay>
                    </TableCell>

                    <TableCell align="center"
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      <MoneyDisplay   fontWeight={400} fontSize={12} variant='body1' >{row.productDetails.product_info.selling_price}</MoneyDisplay>
                    </TableCell>

                    <TableCell  align="center">
                      <MoneyDisplay   fontWeight={400} fontSize={12} variant='body1'>{row.threshold_price}</MoneyDisplay>
                    </TableCell>

                    <TableCell width={'10%'} align="center" sx={{ alignItems: 'center', }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button variant='outlined'
                          onClick={() => toggleAlert(row)}
                          sx={{
                            width: '40%',
                            color: row.is_active ? '#008000' : '#FF3B30',
                            borderRadius: '15px',
                            fontSize: '11px',
                            borderColor: `${row.is_active ? '#008000' : '#FF3B30'} !important`,
                            padding: '1px 45px',

                          }}>
                          {row.is_active ? 'Active' : 'Inactive'}
                        </Button>
                        <MoreVertIcon onClick={(event) => handleIconClick(event, row.product_id)} fontSize='small' />
                        <Menu
                          sx={{ boxShadow: 'none' }}
                          anchorEl={anchorEl ? anchorEl.target : null}
                          open={Boolean(anchorEl && anchorEl.productId === row.product_id)}
                          onClose={handleClose}
                        >
                          <MenuItem sx={{ boxShadow: 'none' }} onClick={() => handleEdit(row)}>
                            <ListItemIcon>
                              <EditIcon fontSize="small" sx={{ color: '#2B2B2B' }} />
                            </ListItemIcon>
                            Edit Alert
                          </MenuItem>

                          <MenuItem sx={{ boxShadow: 'none' }} onClick={() => handleDelete(row.alert_id)}>
                            <ListItemIcon>
                              <DeleteIcon fontSize="small" sx={{ color: '#2B2B2B' }} />
                            </ListItemIcon>
                            Delete Alert</MenuItem>
                        </Menu>
                        <Snackbar
                          open={open}
                          autoHideDuration={6000}
                          onClose={handleAlertClose}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                          <Alert onClose={handleClose} severity={snackMode} sx={{ width: '100%' }}>
                            {message}
                          </Alert>
                        </Snackbar>
                      </Box>

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          {values.length >5 &&
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: 2, padding: '10px', }}>
            <Box className='page-change-tex-box'  >
              <Typography variant='body1' fontWeight='bold'>Go to</Typography>
              <TextField
                type="number"
                value={page + 1}
                onChange={(e) => setPage(Math.max(0, parseInt(e.target.value, 10) - 1))}
                InputProps={{
                  inputProps: { min: 1, max: Math.ceil(values.length / rowsPerPage) },
                  sx: { borderRadius: 1, '& .MuiOutlinedInput-input': { padding: '6.5px 10px' }, width: '50px' }
                }}
                sx={{ width: '50px' }}
              />
              <Typography variant='body1' fontWeight='bold'>page</Typography>
            </Box>
            <Pagination
              // hideNextButton='true'
              // hidePrevButton='true'
              count={Math.ceil(values.length / rowsPerPage)}
              page={page + 1}
              onChange={(event, value) => handleChangePage(event, value - 1)}
              variant="outlined"
              shape="rounded"
              color='primary'
              sx={{
                '&.MuiPaginationItem-root': { fontWeight: 'bold' },
                '& .MuiPaginationItem-root': {
                  color: 'black',
                  backgroundColor: 'transparent',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#1da1f2',
                  color: 'white',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#1da1f2',
                  color: 'white',
                },
              }}
              renderItem={(item) => (

                <PaginationItem
                  {...item}
                  slots={{
                    previous: BackButton,
                    next: NextButton
                  }}
                />
              )}
            />

            <Box className='page-select-tex-box' >
              <Typography variant='body1'>Result Per Page</Typography>
              <TextField
                select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                sx={{ width: '60px' }}
                InputProps={{ sx: { borderRadius: 1, '& .MuiOutlinedInput-input': { padding: '6.5px 10px' }, paddingRight: '0px' } }}
              >
                {[5, 10, 20, 50].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Stack>}
        </TableContainer>

      </Box>
    )
  );
}