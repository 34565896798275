import React, { useEffect, useRef, useState } from 'react';
import ProductCard from './ProductCard'; // Make sure the path is correct
import { Box } from '@mui/material';
import EmptyState from './ProductsEmpty';

function ProductList({ products }) {
 const [visibleItems,setVisibleItems]=useState(5)
 const observerRef= useRef()

 const loadMoreItems = (entries) => {
    const [entry] = entries; 
    if (entry.isIntersecting) {
      setVisibleItems((prev) => prev + 10);
    }
  };


  useEffect(() => {
    const observer = new IntersectionObserver(loadMoreItems, {
      root: null, 
      threshold: 1.0, 
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, []);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {products && products.length>0?products.slice(0,visibleItems).map((product,index) => (
                <Box key={index}>
                <ProductCard key={product.product_id} product={product} />
                <Box ref={observerRef} className="load-more">
                </Box>
                </Box>
            )):<EmptyState/>}
        </Box>
    );
}

export default ProductList;
