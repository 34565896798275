import React, { useState } from 'react';
import { List, ListItem, Checkbox, Typography, Divider, FormGroup, Accordion, AccordionSummary, AccordionDetails, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriceRangeFilter from './PriceRange';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const FacetList = ({ facets, onFacetChange }) => {
  const [priceRange, setPriceRange] = useState([0, 2000]);
  const [expandedFacets, setExpandedFacets] = useState({});


  const handleRangeChange = (newRange) => {
    setPriceRange(newRange);
    // Handle price range change, e.g., filter search results based on newRange
    console.log('Selected price range:', newRange);
  };


  const handleShowAllToggle = (facet) => {
    setExpandedFacets((prevExpandedFacets) => ({
      ...prevExpandedFacets,
      [facet]: !prevExpandedFacets[facet],
    }));
  };

  return (
    <Box sx={{ boxShadow:3,padding:2}}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'space-between',margin:'10px'}}>
        <Typography variant="h6" sx={{ fontSize: '1rem' }}>Filters</Typography>
        <TuneOutlinedIcon sx={{ fontSize: '1.2rem' }} />
      </Box>
      <Divider />
      <Box sx={{ mb: 1,mt:1 }}>
        <Box sx={{padding:"8px"}}>
          <Typography >Price Range</Typography>
            <PriceRangeFilter minPrice={0} maxPrice={2000000} onRangeChange={handleRangeChange} />
          <Divider />
        </Box>


      </Box>

      {Object.keys(facets).map(facet => (
        <Box key={facet} sx={{ mb: 1 }}>
          <Accordion defaultExpanded={true} 
          sx={{ fontSize: '0.875rem', p: 0.5 ,boxShadow:'none'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '1rem' }} />}
             aria-controls={`${facet}-content`} id={`${facet}-header`}>
              <Typography>{facet}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
               <FormGroup>
                <List sx={{ }}>
                  {facets[facet].slice(0, expandedFacets[facet] ? facets[facet].length : 3).map(option => (
                    <ListItem key={option.value} sx={{ p: 0.5, fontSize: '0.75rem' }}>
                      <Checkbox size='small' checked={option.selected} onChange={() => onFacetChange(facet, option.value)} />
                      <Typography>{option.label} ({option.count})</Typography>
                    </ListItem>
                  ))}
                </List>
              </FormGroup>
              {facets[facet].length > 3 && (
                <Button size="large" onClick={() => handleShowAllToggle(facet)}>
                  {expandedFacets[facet] ? 'Show Less' : `Show All(${facets[facet].length})`}
                </Button>
              )}
            </AccordionDetails>
            <Divider sx={{ mt: 2, mb: 2, borderColor: 'grey.400' }}/>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
};

export default FacetList;