import React, { useRef } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BasicBreadcrumbs from '../components/navigate/Navigate';
import { ImageCard } from '../components/ImageCard';
import { Product_title } from '../components/TitleCard';
import { WebCard } from '../components/WebCard1';
import { Review } from '../components/Review';
import { Mycharts } from '../components/Charts';
import { WebDetails } from '../components/WebDetails';
import { Specification } from '../components/Specification';
import RelatedProductList from '../components/RelatedProductList';

export const ProductView = () => {
  const navigate = useNavigate()
  const { product_id } = useParams();

  const [searchParams] = useSearchParams();
  
  // Get a specific query parameter, e.g., 'query'
  const ecommerce = searchParams.get('ecommerce');  

  const specRef = useRef(null)

  const scrollToSpecification = () => {
    if (specRef.current) {
      specRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const margineStyle = {
    margin: '20px 0'
  }
  return (
    <Box className='view-body'>
      <Box >
        <BasicBreadcrumbs />
        <Link href="/search" underline="none" >
          <Typography fontWeight="bold" color='black' onClick={() => navigate(-1,{ replace: false })} >  &lt; Back</Typography>
        </Link>
      </Box>

      <Box mb={2} mt={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'center' }}>
        {/* <ImageCard /> */}
        <Product_title scrollToSpecification={scrollToSpecification} productId={product_id} ecommerce={ecommerce} />
      </Box>
      <Box sx={margineStyle}>
        <WebCard productId={product_id} ecommerce={ecommerce}/>
      </Box>
      <Box sx={margineStyle}>
        <Mycharts />
      </Box>
      <Box sx={{ ...margineStyle, border: '1px solid black', borderRadius: '15px' }} >
        <Review />
        <WebDetails />
      </Box>
      <Box ref={specRef} sx={margineStyle}>
        <Specification />
      </Box>
      <Box sx={margineStyle}>
        <RelatedProductList />
      </Box>
    </Box>
  )
}