import { Box, Button, List, ListItem, Typography, Pagination, Stack, TextField, MenuItem, Divider, PaginationItem } from '@mui/material';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoneyDisplay from './MoneyDisplay';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import s232 from '../assets/s232.jpeg';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import '../components/css/Notificationlist.css'


const calculatePercentage = (threshold_price, current_price) => {
  console.log("data in calculate", threshold_price, current_price)
  if (isNaN(threshold_price) || isNaN(current_price) || threshold_price === 0) return 0;
  return parseFloat(((threshold_price - current_price) / threshold_price * 100).toFixed(0));
};

const PriceDisplay = ({ label, price }) => {
  console.log("data in price in notification",label,price)
  return (
    <Box className='notification-money-display' sx={{borderColor:"#47474799"}}>   
      <Typography  sx={{font:"Inter", fontSize:"10px",color:"#47474799",fontWeight:"400"}} className='notification-money-display-label' >{label}</Typography>      
      <MoneyDisplay styles={{font:"Inter",fontSize:"14px",lineHeight:"17px",fontWeight:"600"}} className='notification-money-display-money' fontSize='14px'>{price}</MoneyDisplay>
    </Box>
  );
};

const BackButton = () => (
  <Typography component="span" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
    <NavigateBeforeIcon /> Back
  </Typography>
);
const NextButton = () => (
  <Typography component="span" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
    Next <NavigateNextIcon />
  </Typography>
);

const Notificationlist = ({ data }) => {
  console.log("data in notification", data)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expandedItem, setExpandedItem] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClick = (alert_history_id) => {
    setExpandedItem(expandedItem === alert_history_id ? null : alert_history_id)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };
  function convertPrice(pricestr) {
    const cleanprice = pricestr.replace(/[₹,]/g, '')
    // console.log("data in convert int for price",cleanprice)
    return parseFloat(cleanprice)
  }
 
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box m={2}>
      <Box className='notification-list-body' sx={{overflow:"auto"}}>
      <List >
        {paginatedData.map((item) => (
          <ListItem key={item.alert_history_id} sx={{ padding: '8px 2px'}}>
            <Box className='body-web' >
              <Box sx={{ border: '1px solid black', borderRadius: '3px',backgroundImage:`url(${item.alert_data.product_image_url})`,backgroundSize:"cover",backgroundPosition:"center" }} width={40} height={40}>
                {/* <img src={item.alert_data.product_image_url}  style={{ marginTop: '5px', objectFit: 'cover', borderRadius: '8px', padding: 2 }} /> */}
              </Box>
              <Box>
                <Typography sx={{fontWeight:"600",fontSize:"20px",lineHeight:"24px",font:"Inter",marginBottom:"8px"}} variant='subtitle1'>{item.alert_data.product_name.slice(0,30)}</Typography>
                <Typography variant='body2' sx={{color:'#47474799',font:"Inter",fontWeight:"500",}}>
                  {item.alert_data.product_id.slice(0,30)}  
                    {''} has dropped from: {item.alert_data.current_price.split('.')[0] } to {Math.floor(item.threshold_price)}
                  {/* {item.alert_data.product_description.slice(0, 60)}... */}
                  </Typography>
              </Box>
              <Box sx={{ flex: 3,width:'20%', marginTop: '24px' }}>
                <Typography sx={{ width: 'fit-content', fontSize: '0.75rem', display: 'flex', alignItems: 'center', mt: 1 }} color='#1CAC70' bgcolor='#1CAC701A' p={0.5} borderRadius={1}>
                  <ArrowDropDownIcon fontSize='small' />{calculatePercentage(item.threshold_price, convertPrice(item.alert_data.current_price))}%
                </Typography>
              </Box>
              <Box display='flex' sx={{ gap: '8px', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: '8px', m: '8px 0', alignItems: 'center',flexDirection:'row',justifyContent:"space-between" }}>
                  <PriceDisplay label='MRP Price'     price={convertPrice(item.alert_data.mrp_price)}     />
                  <PriceDisplay label='Alert Price'   price={item.threshold_price}           />
                  <PriceDisplay label='Dropped Price' price={convertPrice(item.alert_data.current_price)}  />
                </Box>
                <Box sx={{ display: 'flex', gap: '7px', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography
                  sx={{color:'#474747B2',font:"Inter",fontSize:"12px",fontWeight:"600"}}
                  >{formatDate(item.alert_triggered_time)}</Typography>
                  <Button variant='contained' sx={{ borderRadius: '20px',padding:'0px 20px', fontSize: '10px',fontWeight:"600" ,font:"Inter" }}>Visit Site</Button>
                </Box>
              </Box>
            </Box>
            <Box className='body-mobile'>
              <Box className='notification-mobile-main'>
            <Box sx={{ border: '1px solid black', borderRadius: '3px',height:'30px'  }}>
                <img src={s232} alt={item.alert_data.product_name} width={20} height={20} style={{ marginTop: '2px', objectFit: 'cover', borderRadius: '8px', padding: 2,}} />
              </Box>
              <Box >
                <Typography fontWeight='bold' variant='subtitle1' sx={{fontSize:'x-small'}}>{item.alert_data.product_name.slice(0,25)}</Typography>
                <Typography variant='body2' sx={{fontSize:'8px',color:'#474747'}}>Price dropped: {item.alert_data.current_price} to {item.threshold_price}</Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '2px', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography 
                  fontSize='xx-small'
                  color='textSecondary'
                  fontWeight='bold'
                  >{formatDate(item.alert_triggered_time)}</Typography>
                  <Typography
                   variant='body2'
                   color='black'
                   fontSize='xx-small'
                   fontWeight='bold'
                   onClick={()=>{handleClick(item.alert_history_id)}}
                   sx={{display:'flex',alignItems:'center'}}
                   >More
                   {expandedItem === item.alert_history_id ? <KeyboardArrowUpIcon fontSize='x-small' /> : <KeyboardArrowDownIcon fontSize='x-small'/>}
                   </Typography>
                </Box>
                </Box>
                <Box>
                  {expandedItem === item.alert_history_id && (
                    <Box sx={{ padding: '5px' }}>
                      <Divider />
                      <Box sx={{ display: 'flex', gap: '8px', m: '8px 0', alignItems: 'center', justifyContent: 'center' }}>
                        <PriceDisplay label='MRP Price' price={convertPrice(item.alert_data.mrp_price)} />
                        <PriceDisplay label='Alert Price' price={item.threshold_price} />
                        <PriceDisplay label='Dropped Price' price={convertPrice(item.alert_data.current_price)} />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      {data.length>5 &&
      <Box className='notification-pagination-body' mb={2} spacing={2}  >
        <Box className='pagination-goto' >
          <Typography variant='body1' fontWeight='bold'>Go to</Typography>
          <TextField
            type="number"
            sx={{ width: '50px' }}
            value={page + 1}
            onChange={(e) => setPage(Math.max(0, parseInt(e.target.value, 10) - 1))}
            InputProps={{
              inputProps: { min: 1, max: Math.ceil(data.length / rowsPerPage), },
              sx: {
                '& .MuiInputBase-input': {
                  padding: '6px 10px',
                  borderRadius: 1,
                }, borderRadius: 1,
              }

            }}
          />
          <Typography variant='body1' fontWeight='bold'>page</Typography>

        </Box>
        <Pagination
          className='notification-pagination'
          count={Math.ceil(data.length / rowsPerPage)}
          page={page + 1}
          onChange={(event, value) => handleChangePage(event, value - 1)}
          variant="outlined"
          shape="rounded"
          color='primary'
          sx={{
            '& .MuiPaginationItem-root': { fontWeight: 'bold' },
            '& .MuiPaginationItem-root': {
              color: 'black',
              backgroundColor: 'transparent',
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: '#1da1f2',
              color: 'white',
            },
            '& .MuiPaginationItem-root:hover': {
              backgroundColor: '#1da1f2',
              color: 'white',
            },
          }}
          renderItem={(item) => (

            <PaginationItem
              {...item}
              slots={{
                previous: 
                BackButton
                ,
                next: 
                    NextButton
              
              }}
            />
          )}
        />
        <Box className='pagination-dropdown'>
          <Typography variant='body1'>Result Per Page</Typography>
          <TextField
            select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            sx={{
              width: '60px', '& .MuiInputBase-input': {
                padding: '6px 10px',
                borderRadius: 1,
              },
            }}
            InputProps={{ sx: { borderRadius: 1, paddingRight: '0' } }}
          >
            {[5, 10, 20, 50].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>}
    </Box>
  );
};

export default Notificationlist;
