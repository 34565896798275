// src/components/LandingPage.jsx
import React, { useEffect, useState } from 'react';
import MouseIcon from '@mui/icons-material/Mouse';
import { TextField, Button, Paper, Typography, Grid2, Box } from '@mui/material';
import { Image, Margin, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Productinlandingpage from '../components/Productlandingpage';
import ImageList from '../components/ImageGrid';
import QuiltedImageList from '../components/ImageGrid';
import UserDetails from '../components/UserDetails';
import TalebisiLogo from '../assets/Talebisi.svg';
import axios from 'axios';

const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  const browsers = [
      { name: 'Microsoft Edge', regex: /Edg/i },
      { name: 'Google Chrome', regex: /Chrome/i },
      { name: 'Safari', regex: /Safari/i },
      { name: 'Mozilla Firefox', regex: /Firefox/i },
      { name: 'Internet Explorer', regex: /MSIE|Trident/i },
  ];

  for (const browser of browsers) {
      if (browser.regex.test(userAgent)) {
          return browser.name;
      }
  }

  return 'Unknown';
};


const LandingPage = () => {
  const[inputValue,setInputValue]=useState('')
  const [browser, setBrowser] = useState('');
    const [location, setLocation] = useState(null);
    const [ip, setIp] = useState('');
    const navigate = useNavigate();
    

  const currentDateTime = new Date();
  const DateandTime = currentDateTime.toLocaleDateString();

    useEffect(() => {
    setBrowser(getBrowserName());
    
    navigator.geolocation.getCurrentPosition(
        (position) => {
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
        },
        (error) => {
            console.error("Error getting location:", error);
            setLocation("Unable to retrieve location");
        }
    );

    const fetchIp = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setIp(response.data.ip);
        } catch (error) {
            console.error('Error fetching IP address:', error);
        }
    };

      fetchIp();
  }, []);
  
  const locationText = location ? `Lat: ${location.latitude || 'Loading..'}, Long: ${location.longitude || 'Loading..'}` : 'Unable to retrieve location';


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = async() => {
    console.log('Search input:', inputValue);
    console.log('Date and Time:', currentDateTime.toLocaleDateString());
    let searchInfo = {
      user_id:"001",
      browser:browser,
      keyword:inputValue,
      userIp:ip,
      location:locationText,
      }
    // const response = await fetch(`${process.env.REACT_APP_API_URL}/search/history`,
    //   {method:"POST",headers:{
    //     "Content-Type":"application/json"
    //   },body:JSON.stringify(searchInfo)})
    
    // if(response.ok){
      navigate(inputValue!==''?`/search?keyword=${inputValue}`:"/search")
    // }else{
    //   alert("cannot connect to db")
    // }
  }

  const handlechange =( event)=>{
    setInputValue(event.target.value)
    
  }
  return (
    <div style={{ display:"flex",
    flexDirection:"column",
    padding: '20px', 
    textAlign: 'center',
    alignItems:'center' }}>
      <img src={TalebisiLogo} width="25%" style={{marginTop:"5%"}}></img>
      <Box style={{ padding: '20px', marginBottom: '20px',minWidth:"70%" }}>
        <Box display={'flex'} alignItems={'center'} gap={2} >
        
          <TextField
            variant="outlined"
            value={inputValue}
            onChange={handlechange}
            placeholder="What are you looking for Today?"
            onKeyUp={handleKeyPress}
            sx={{
              borderRadius: '50px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                height: '40px', // Adjust height as needed
              },
              '& .MuiOutlinedInput-input': {
                padding: '10px 14px', // Adjust padding to fit within the height
              },
            }}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSearch} style={{ borderRadius: '50px', height: '40px' }} endIcon={<Search />}>
            Search
          </Button>
        </Box>
      </Box>

      <Grid2 sx={{minWidth:"70%" }}>

        <Paper style={{ padding: '10px', height: '150px', backgroundColor: 'primary'}}>
          <Typography>Advertising Banner 1</Typography>
        </Paper>
      </Grid2>
      <Box sx={{ padding: '40px 0',height:"300px",alignItems:"center",display:"flex" }}>
        <MouseIcon />
        <Typography>
          Scroll to view products
        </Typography>
      </Box>
      <Box sx={{display:'flex', justifyContent:'center'}} margin={5}>
        <QuiltedImageList/>
      </Box>
      <Box sx={{display:'flex', justifyContent:'center'}}  margin={5}>
        <Productinlandingpage />
      </Box>
      <Box sx={{display:'flex', justifyContent:'center'}}>
      </Box>
    </div>
  );
};

export default LandingPage;
