import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, ImageListItemBar} from '@mui/material';


export default function QuiltedImageList() {
    return ( 
        <Box sx={{Padding:'10px'}} mt={2}>
            <ImageList sx={{borderRadius:'15px'}} cols={5} gap={8} >
            {itemData.map((item,index)=>(
             <ImageListItem  cols={item.cols || 1} rows={item.rows || 1} key={index}>   
                 <img style={{borderRadius:'15px'}} src={item.img} alt={item.title}/>
                 <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  borderRadius:'15px'
              }}
              title={item.title}
              position="top"
            //   actionIcon={
            //     <IconButton
            //       sx={{ color: 'white' }}
            //       aria-label={`star ${item.title}`}
            //     >
            //     {item.title}
            //     </IconButton>
            //   }
            //   actionPosition="left"
            />               
              </ImageListItem>
            ))}
            </ImageList>
            
        </Box>
    );
}

const itemData = [
    {
        img: 'https://th.bing.com/th/id/OIP.hReJMzLg1TeZjiE_bnGaDQHaEK?w=333&h=187&c=7&r=0&o=5&dpr=1.3&pid=1.7',
        title: 'Tabs',
        cols:2,
       
    },
    {
        img: 'https://th.bing.com/th/id/OIP.PltwFLlq88ROjzJV8VN7LAHaE8?w=265&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7',
        title: 'Washing Measign',
        rows:2
    },
    {
        img: 'https://images.pexels.com/photos/459653/pexels-photo-459653.jpeg?auto=compress&cs=tinysrgb&w=600',
        title: 'laptops',
        cols:2,
        rows:2
    },
    {
        img: 'https://th.bing.com/th/id/OIP.b5YJu3nOENEr0t3obBYo4AHaE7?w=288&h=192&c=7&r=0&o=5&dpr=1.3&pid=1.7',
        title: 'Mobiles',
        cols:2
    },
];


