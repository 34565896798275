import s232 from '../assets/s232.jpeg';

const productData = [
  {
    product_id: 1,
    category: 'Air Purifier',
    product_name: 'Philips AC1711 Smart Air Purifier',
    best_price: 12199,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 2,
    category: 'Air Purifier',
    product_name: 'Dyson TP07 Air Purifier',
    best_price: 44999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 3,
    category: 'Air Purifier',
    product_name: 'Mi Air Purifier 3',
    best_price: 10999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 4,
    category: 'Air Purifier',
    product_name: 'Honeywell Air Touch i8',
    best_price: 15499,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 5,
    category: 'Air Purifier',
    product_name: 'Sharp Air Purifier FP-F40E-W',
    best_price: 13999,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 6,
    category: 'Air Purifier',
    product_name: 'Blueair Blue Pure 211',
    best_price: 22999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 7,
    category: 'Air Purifier',
    product_name: 'Kent Aura Room Air Purifier',
    best_price: 8500,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 8,
    category: 'Dishwasher',
    product_name: 'Bosch 13 Place Settings Dishwasher',
    best_price: 43800,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 9,
    category: 'Dishwasher',
    product_name: 'IFB Neptune VX Fully Electronic Dishwasher',
    best_price: 35999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 10,
    category: 'Dishwasher',
    product_name: 'Voltas Beko 8 Place Dishwasher',
    best_price: 24999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 11,
    category: 'Dishwasher',
    product_name: 'LG D1452CF Dishwasher',
    best_price: 52999,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 12,
    category: 'Dishwasher',
    product_name: 'Elica WQP12-7605V Dishwasher',
    best_price: 21999,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 13,
    category: 'Dishwasher',
    product_name: 'Faber FFSD 6PR 12S Dishwasher',
    best_price: 26999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 14,
    category: 'Dishwasher',
    product_name: 'Siemens SN256I01GI Dishwasher',
    best_price: 62999,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 15,
    category: 'Refrigerator',
    product_name: 'LG 260 L 3 Star Frost-Free Double Door Refrigerator',
    best_price: 24490,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 16,
    category: 'Refrigerator',
    product_name: 'Samsung 253L 3 Star Inverter Frost-Free Double Door Refrigerator',
    best_price: 23999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 17,
    category: 'Refrigerator',
    product_name: 'Whirlpool 190 L 3 Star Single Door Refrigerator',
    best_price: 13990,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 18,
    category: 'Refrigerator',
    product_name: 'Haier 320 L 3 Star Frost-Free Double Door Refrigerator',
    best_price: 28990,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 19,
    category: 'Refrigerator',
    product_name: 'Godrej 236 L 2 Star Inverter Frost-Free Double Door Refrigerator',
    best_price: 19999,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 20,
    category: 'Refrigerator',
    product_name: 'Panasonic 307 L 3 Star Frost-Free Double Door Refrigerator',
    best_price: 27999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 21,
    category: 'Refrigerator',
    product_name: 'Samsung 324L 3 Star Inverter Frost Free Double Door Refrigerator',
    best_price: 31990,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 22,
    category: 'Washing Machine',
    product_name: 'LG 6 Kg Fully Automatic Front Load Washing Machine',
    best_price: 31999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 23,
    category: 'Washing Machine',
    product_name: 'Samsung 7.5 Kg Fully Automatic Top Load Washing Machine',
    best_price: 21499,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 24,
    category: 'Washing Machine',
    product_name: 'IFB 6.5 Kg Fully Automatic Front Load Washing Machine',
    best_price: 28999,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 25,
    category: 'Washing Machine',
    product_name: 'Whirlpool 6.2 Kg Semi-Automatic Top Load Washing Machine',
    best_price: 11499,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 26,
    category: 'Washing Machine',
    product_name: 'Bosch 6.5 Kg Fully Automatic Front Load Washing Machine',
    best_price: 32999,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 27,
    category: 'Washing Machine',
    product_name: 'Panasonic 6.5 Kg Semi-Automatic Top Load Washing Machine',
    best_price: 13999,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 28,
    category: 'Washing Machine',
    product_name: 'Godrej 6.5 Kg Fully Automatic Top Load Washing Machine',
    best_price: 15999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 29,
    category: 'Microwave',
    product_name: 'LG 28 L Convection Microwave Oven',
    best_price: 16999,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 30,
    category: 'Microwave',
    product_name: 'IFB 20 L Convection Microwave Oven',
    best_price: 10499,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 31,
    category: 'Microwave',
    product_name: 'Samsung 23 L Solo Microwave Oven',
    best_price: 6499,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 32,
    category: 'Microwave',
    product_name: 'Panasonic 27 L Convection Microwave Oven',
    best_price: 14999,
    product_image: s232,
    rating: 5,
  },
  {
    product_id: 33,
    category: 'Microwave',
    product_name: 'Bajaj 17 L Solo Microwave Oven',
    best_price: 4899,
    product_image: s232,
    rating: 3,
  },
  {
    product_id: 34,
    category: 'Microwave',
    product_name: 'Whirlpool 30 L Convection Microwave Oven',
    best_price: 19999,
    product_image: s232,
    rating: 4,
  },
  {
    product_id: 35,
    category: 'Microwave',
    product_name: 'Haier 28 L Convection Microwave Oven',
    best_price: 15999,
    product_image: s232,
    rating: 4,
  }
];

export default productData;
