import React, { useState } from 'react';
import { Button, Stack, Typography, CircularProgress, Alert, List, ListItem, ListItemText } from '@mui/material';

const TriggerBatches = () => {
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    // Handler for triggering the Alert Batch
    const handleAlert = async () => {
        setLoading(true);
        setErrorData(null);
        setSuccessMessage("");

        try {
            console.log("Triggering Alert Batch");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/trigger_alert`);
            const responseData = await response.json();

            // Check if error data is present
            if (responseData && Object.keys(responseData).length > 0) {
                setErrorData(responseData);
                console.error("Some failures in Alert Batch:", responseData);
            } else {
                setSuccessMessage("Alert Batch triggered successfully.");
            }
        } catch (error) {
            setErrorData({ message: "Failed to trigger Alert Batch." });
            console.error("Request Error:", error);
        } finally {
            setLoading(false);
        }
    };


    // Handler for triggering the Notification Batch
    const handleNotification = async () => {
        setLoading(true);
        setErrorData(null);
        setSuccessMessage("");

        try {
            console.log("Triggering Notification Batch");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/alerts/trigger_notification`);

            if (!response.ok) {
                setSuccessMessage("Notification Batch failed.");
            } else {
                setSuccessMessage("Notification Batch triggered successfully.");
            }
        } catch (error) {
            console.error("Request Error:", error);
            setSuccessMessage("Failed to trigger Notification Batch.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Stack spacing={3} alignItems="center" sx={{ mt: 5 }}>
            <Typography variant="h4">Batch Trigger Dashboard</Typography>

            {/* Show Spinner when loading */}
            {loading && <CircularProgress />}

            {/* Error message display */}
            {/* Error List Display */}
            {errorData && (
                <Alert severity="error" sx={{ width: '100%' }}>
                    <Typography variant="subtitle1">Errors:</Typography>
                    <List>
                        {Object.entries(errorData).map(([key, value], index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${key}: ${JSON.stringify(value)}`} />
                            </ListItem>
                        ))}
                    </List>
                </Alert>
            )}
            {/* Success message display */}
            {successMessage && (
                <Alert severity="success">
                    {successMessage}
                </Alert>
            )}

            <Button
                variant="contained"
                color="primary"
                onClick={handleAlert}
                disabled={loading}
            >
                Trigger Alerts
            </Button>

            <Button
                variant="contained"
                color="secondary"
                onClick={handleNotification}
                disabled={loading}
            >
                Trigger Notification
            </Button>
        </Stack>
    );
};

export default TriggerBatches;
