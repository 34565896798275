import { Box, Button, Rating, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MoneyDisplay from './MoneyDisplay';
import EmptyState from './ProductsEmpty';
import { ImageCard } from './ImageCard';
import ProductTitleSkeleton from './ProductTitleSkeleton';
import '../components/css/ProductDetailsTitle.css'

export const Product_title = ({ scrollToSpecification, productId, ecommerce }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const fetchProduct = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_EXTERNAL_API}/products/${productId}?ecommerce=${ecommerce}`)
            const result = await response.json();
            if (response.ok) {
                console.log("product title",result)
                setData(Array.isArray(result) ? result : [result]);
                setLoading(false)
            } else {
                setError(true)

            }
        } catch (error) {
            console.error("internal error", error);
            setError(true);
        } finally {
            setLoading(false)
        }
    };


    useEffect(() => {
        fetchProduct()
    }, [])
    console.log("data in tittle", data)

    return (
        <Box width={'100%'}>
            {loading ? (
                <ProductTitleSkeleton/>
        ) : (
                data.map((item, index) => (
                    <Box key={index} className='product-details-front-page' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',padding:'10px' }}>
                        <Box className='product-details-image-body' sx={{width:'50%'}} >
                            <ImageCard images={item.product_image_urls} />
                        </Box>
                        <Box className='product-details-title-body'  >
                            <Box>
                                <Typography variant="p" component="div" color='#686666'>
                                    {item.ecommerce.site_name}
                                </Typography>
                                <Typography variant='h5' color='black' fontWeight='bold' >
                                    {item.product_title.slice()}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', margin: '10px 0 10px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', }}>
                                    <Typography component='h6' fontStyle={'bold'}>{`${item.rating}`}</Typography>
                                    <Rating size='medium' value={item.rating} readOnly  precision={0.5} />
                                    <Typography variant="body2">
                                        {`(${item.total_no_of_ratings} Ratings)`}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" style={{ marginLeft: 'auto', cursor: 'pointer', color: 'blue' }} >
                                    <span
                                        style={{ fontWeight: 'bold', color: 'black', cursor: 'pointer' }}
                                        onClick={scrollToSpecification}
                                    >
                                        View more
                                    </span>
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {item.product_description.slice(0, 150)}
                            </Typography>
                            <Box mt={2}  sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography variant='p' sx={{ color: '#6C757D' }} color="textSecondary" fontWeight="Bolt">
                                        Best Price
                                    </Typography>
                                    <MoneyDisplay variant="h4">{item.best_price}</MoneyDisplay>
                                    <Typography variant="p" color='#686666'>
                                        (Price range starts from{' '}<MoneyDisplay variant="p" color="black" >{item.priceRange[0]}</MoneyDisplay>{' '}
                                        to <MoneyDisplay variant="p" color="black">{item.priceRange[1]}</MoneyDisplay>)
                                    </Typography>
                                </Box>
                                <Box className='product-details-button-group' width={'100%'} sx={{ display: "flex", height: "", gap: "24px", marginTop: '35px' }}>
                                    <Button 
                                    className='product-details-button'
                                    variant='outlined' 
                                    sx={{ width: '120px', color: '#6C757D', borderRadius: "40px" }}
                                     onClick={() => window.open(item.product_url, "_blank", "noopener,noreferrer")}
                                     >
                                        Visit Site</Button>

                                    <Button 
                                    className='product-details-button'
                                    variant='contained' 
                                    onClick={() => navigate(`/set-alert/${productId}`)} 
                                    sx={{ borderRadius: "40px" }} endIcon={<NotificationsActiveIcon />}>Set Alert</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )
                ))}
        </Box>
    )
}