import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { LineChart, lineElementClasses } from '@mui/x-charts';
import React, { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import '../components/css/Chart.css'

const price = [11000, 18000, 12000, 12000, 9900, 12000, 15000, 9000, 12000, 11000, 9000, 18000];
const month = [
    'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
];
const formatPrice = (price) => {
    return (price / 1000).toFixed(0) + 'k';
}

export const Mycharts = () => {
    const [displaydata, setDisplaysata] = useState({ months: month, prices: price });
    const [isactive, setIsactive] = useState('year');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (filter) => {
        console.log(`Selected filter: ${filter}`);
        setAnchorEl(null);
    };

    const showLast3Months = () => {
        setDisplaysata({ prices: price.slice(-3), months: month.slice(-3) });
        setIsactive('3months');
        setAnchorEl(null);
    };
    const showLast6Months = () => {
        setDisplaysata({ prices: price.slice(-6), months: month.slice(-6) });
        setIsactive('6months');    
        setAnchorEl(null);

    };
    const showLastYear = () => {
        setDisplaysata({ prices: price, months: month });
        setIsactive('year');
        setAnchorEl(null);
    };
    const formattedPrices = displaydata.prices.map(formatPrice);
    console.log("data" + formattedPrices)
    return (
        <Box>
            <Box mt={5} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h5' fontWeight="bold">
                    Compare Price
                </Typography>

                <Box className='chart-filter-button' gap={1} >
                    <Button onClick={showLast3Months} color={isactive === '3months' ? 'primary' : 'default'} variant={isactive === '3months' ? 'contained' : 'outlined'} sx={{ borderRadius: "40px" }}>
                        last 3 months
                    </Button>
                    <Button onClick={showLast6Months} color={isactive === '6months' ? 'primary' : 'default'} variant={isactive === '6months' ? 'contained' : 'outlined'} sx={{ borderRadius: "40px" }}>
                        last 6 months
                    </Button>
                    <Button onClick={showLastYear} color={isactive === 'year' ? 'primary' : 'default'} variant={isactive === 'year' ? 'contained' : 'outlined'} sx={{ borderRadius: "40px" }}>
                        last 1 year
                    </Button>
                </Box>
                <Box className='chart-filtre-mobile'>
                    <Button
                    sx={{borderRadius:'18px' ,}}
                    color='#6C757D'
                        onClick={handleClick}
                        variant="outlined"
                        endIcon={<FilterListIcon/>}
                    >
                        Filter
                    </Button>
                    <Menu
                   
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose('')}
                    MenuListProps={{
                      'aria-labelledby': 'filter-button',
                    }}
                    >
                        <MenuItem onChange={showLast3Months} onClick={showLast3Months}>Last 3 Months</MenuItem>
                        <MenuItem onClick={showLast6Months}>Last 6 Months</MenuItem>
                        <MenuItem onClick={showLastYear}>Last Year</MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Box display={'flex'} sx={{
                height: "300px",
                border: "1px solid #c6c9cc",
                marginTop: "16px",
                padding: "16px",
                borderRadius: "15px",
            }} justifyContent={'space-evenly'}>
                <Box sx={{
                    marginTop: '16px',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {/* <span>Product </span>
                    <span>Price</span> */}
                </Box>
                <LineChart
                    sx={{
                        [`& .${lineElementClasses.root}`]: {
                            stroke: '#c6c9cc',
                            strokeWidth: 2,
                        },
                    }}
                    series={[
                        { data: displaydata.prices, showLine: true },
                    ]}
                    xAxis={[{ scaleType: 'point', data: displaydata.months }]}
                />

            </Box>
        </Box>
    );
}
