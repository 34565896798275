import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter/400.css'; // Add this line to load the Inter font
import { Box, Button, createTheme, ThemeProvider } from '@mui/material';
import { Route, Router, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Layout from './pages/Layout';
import SearchResult from './pages/SearchResultPage';
import QuiltedImageList from './components/ImageGrid';
import { Provider } from 'react-redux';
import store from './store/Store';

const theme = createTheme({
  palette: {
    border: {
      main: '#c6c9cc', // Replace with your desired border color
      primary:'#1DA1F2',
      secondary:'#F4A403',
      

    },
    primary: {
      main: '#1da1f2', 
      contrastText: '#ffffff',  // White text for primary buttons
      // Default button color
    },
    secondary: {
      main: '#f50057',  // Secondary button color
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif', // Ensure Inter is included
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',  
        },
      },
    },SearchResult
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
     <Layout/>
     </Provider>
    </ThemeProvider>
      
  );
}

export default App;
