import React from 'react'
import { Box, Button, Card, Fab, Typography } from '@mui/material'
import { useState } from 'react'

export const WebDetails = () => {

    const [isExpanded, setIsExpanded] = useState(false)

    const data = [{
        Tittle: 'Amazon',
        description: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Et tempore eius fugit. Illum enim repellendus mollitia aliquid libero consequuntur eos laborum itaque doloribus architecto consectetur, hic iste sit et similique officiis ipsa ex voluptatem tenetur! Impedit itaque autem adipisci pariatur doloremque. Ipsam beatae non quam quia nihil, exercitationem minus officia.",
        Date: ' 23 August,2024'

    },
    {
        Tittle: 'flipkart',
        description: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Et tempore eius fugit. Illum enim repellendus mollitia aliquid libero consequuntur eos laborum itaque doloribus architecto consectetur, hic iste sit et similique officiis ipsa ex voluptatem tenetur! Impedit itaque autem adipisci pariatur doloremque. Ipsam beatae non quam quia nihil, exercitationem minus officia.",
        Date: ' 23 August,2024'

    },
    {
        Tittle: 'Ebay',
        description: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Et tempore eius fugit. Illum enim repellendus mollitia aliquid libero consequuntur eos laborum itaque doloribus architecto consectetur, hic iste sit et similique officiis ipsa ex voluptatem tenetur! Impedit itaque autem adipisci pariatur doloremque. Ipsam beatae non quam quia nihil, exercitationem minus officia.",
        Date: ' 23 August,2024'

    },
    {
        Tittle: 'Mesho',
        description: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Et tempore eius fugit. Illum enim repellendus mollitia aliquid libero consequuntur eos laborum itaque doloribus architecto consectetur, hic iste sit et similique officiis ipsa ex voluptatem tenetur! Impedit itaque autem adipisci pariatur doloremque. Ipsam beatae non quam quia nihil, exercitationem minus officia.",
        Date: ' 23 August,2024'

    },
    ]
    const deatiltoShow = isExpanded ? data : data.slice(0, 2)
    return (
        <Box mt={3} sx={{ padding: '20px'}}>
            <Box>
                {deatiltoShow.map((item, index) => {
                    return (
                        <Box key={index} sx={{ margin: '20px',padding:"10px",backgroundColor:"#fafafa" }}>
                            <Box margin='0 20px' display='flex' justifyContent='space-between'>
                                <Typography variant='h5'>
                                    {item.Tittle}
                                </Typography>
                                <Typography variant='h7'>
                                    {item.Date}
                                </Typography>
                            </Box >
                            <Typography color='textSecondary' sx={{ margin: '0 20px' }}>
                                {item.description}
                            </Typography>
                        </Box>
                    )
                })}
                <Box display={'flex'} justifyContent={'center'}>
                    <Button size='small' variant="outlined" sx={{ width: '100px',color:"gray",borderRadius:"50px" }} onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? 'View less' : 'View All'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
