import React from 'react';
import { Box, Typography, Slider, TextField } from '@mui/material';

const PriceRangeFilter = ({ minPrice, maxPrice, onRangeChange }) => {
  const [range, setRange] = React.useState([minPrice, maxPrice]);
  const [minInput, setMinInput] = React.useState(minPrice);
  const [maxInput, setMaxInput] = React.useState(maxPrice);

  const handleSliderChange = (event, newValue) => {
    setRange(newValue);
    setMinInput(newValue[0]);
    setMaxInput(newValue[1]);
    onRangeChange(newValue);
  };

  const handleMinInputChange = (event) => {
    const value = Number(event.target.value);
    if (value >= minPrice && value <= range[1]) {
      const newRange = [value, range[1]];
      setRange(newRange);
      setMinInput(value);
      onRangeChange(newRange);
    }
  };

  const handleMaxInputChange = (event) => {
    const value = Number(event.target.value);
    if (value >= range[0] && value <= maxPrice) {
      const newRange = [range[0], value];
      setRange(newRange);
      setMaxInput(value);
      onRangeChange(newRange);
    }
  };

  return (
    <Box sx={{ width: 300, mb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2,mb:2 }}>
        <TextField
          label="Min Price"
          variant="outlined"
          size="small"
          value={minInput}
          onChange={handleMinInputChange}
          sx={{ width: '45%' }}
        />
        <TextField
          label="Max Price"
          variant="outlined"
          size="small"
          value={maxInput}
          onChange={handleMaxInputChange}
          sx={{ width: '45%' }}
        />
      </Box>
      <Slider
        value={range}
        onChange={handleSliderChange}
        min={minPrice}
        max={maxPrice}
        step={10}
        aria-labelledby="range-slider"
        getAriaValueText={(value) => `$${value}`}
      />
    </Box>
  );
};

export default PriceRangeFilter;
